import { Subject } from 'rxjs';
import { Facility } from '../models/facility.model';
import { MapWrangler } from '../models/weavix-map.model';
import { PermissionAction } from '../permissions/permissions.model';
import { Utils } from '../utils/utils';
import { FacilityService } from './facility.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class WranglerStateService extends StateServiceBase {
    wranglers: Map<string, MapWrangler> = new Map();
    wranglers$: Subject<StateUpdate<MapWrangler>> = new Subject();

    constructor(
        private facilityService: FacilityService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;
        const facility = await this.facilityService.get(c, facilityId, false);
        this.wranglers = Utils.toMap(facility.wranglers.map(b => b));
    }

    async stop() {
        super.stop();
        this.wranglers.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;

        return (await this.facilityService.subscribeFacilityUpdates(c, accountId, facilityId)).subscribe(async result => {
            const facility = Object.values(result.payload)[0] as Facility;
            const wranglersMap = Utils.toObjectMap(facility.wranglers.map(b => b), b => b.id, b => b);
            const updatedIds = Object.keys(wranglersMap);
            const deletedWranglers = Array.from(this.wranglers.values()).filter(b => !updatedIds.some(id => id === b.id));
            const updateMap = Object.assign({} , wranglersMap, Utils.toObjectMap(deletedWranglers, b => b.id, b => null));

            this.updateFromMap(this.wranglers, updateMap, this.wranglers$);
        });
    }
}
