import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { AnyBadgeEvent, BadgeUpdate, EventType } from '../models/event.model';
import { MapFloorPlan } from '../models/floor-plan.model';
import { GeofenceType } from '../models/geofence-type.model';
import { Person } from '../models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { Geofence } from '../models/weavix-map.model';
import { BoxTree, Fence } from '../utils/polygon';
import { AccountService } from './account.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';
import { TranslationService } from './translation.service';

@Injectable({
    providedIn: 'root',
})
export class GeofenceService {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private translationService: TranslationService,
    ) { }

    private static readonly cacheContext: CacheContext = { collection: 'Geofences', maxAge: 1800000 };

    public static clearCache = () => HttpService.clearCache(GeofenceService.cacheContext.collection);

    /**
     * @param geofences the geofences you want to check
     * @param people people you want to check against geofences
     * @param floorPlans floor plans you check against people ang geofences
     * @returns object by geofence id to array of people inside. {[geofenceId]: Person[]}
     */
    public static getPeopleInsideGeofences(geofences: Geofence[], badgeUpdates: BadgeUpdate[], floorPlans: {[key: string]: MapFloorPlan} = {}): {[key: string]: Person[]} {
        const geofencesWithPeopleInside = {};
        const geofenceToFloorPlanMap = {};
        const checkFp: boolean = !!Object.keys(floorPlans).length;
        const geofenceTree = new BoxTree<Geofence, any>((obj) => obj.vertices, 0);
        geofences.forEach(geofence => {
            geofencesWithPeopleInside[geofence.id] = [];
            geofenceTree.add(geofence);
            if (checkFp && floorPlans[geofence.floorPlanId]) {
                geofenceToFloorPlanMap[geofence.id] = floorPlans[geofence.floorPlanId];
            }
        });

        badgeUpdates.forEach(update => {
            const inside = {};
            geofenceTree.find([{ location: update.location }], inside);
            Object.keys(inside).forEach((gId: string) => {
                const geofenceLevel = geofenceToFloorPlanMap?.[gId]?.level;
                if (!checkFp || geofenceLevel === update?.level) {
                    inside[gId].forEach(x => geofencesWithPeopleInside[gId].push(update));
                }
            });
        });

        return geofencesWithPeopleInside;
    }

    async get(component: any, id: string) {
        return this.httpService.get<Geofence>(component, `/track/geofences/${id}`, null, GeofenceService.cacheContext);
    }

    async getAll(component: any, facilityId?: string, tags?: string[]) {
        return this.httpService.get<Geofence[]>(component, '/track/geofences', { tags, facilityId }, GeofenceService.cacheContext);
    }

    async getTypes(component: any) {
        return this.httpService.get<GeofenceType[]>(component, '/core/geofence-types', null, GeofenceService.cacheContext);
    }

    async add(component: any, geofence: Geofence) {
        return this.httpService.post<Geofence>(component, `/track/geofences`, geofence, GeofenceService.cacheContext);
    }

    async update(component: any, id: string, geofence: Geofence) {
        return this.httpService.put<Geofence>(component, `/track/geofences/${id}`, geofence, GeofenceService.cacheContext);
    }

    async delete(component: any, id: string) {
        return this.httpService.delete<void>(component, `/track/geofences/${id}`, null, GeofenceService.cacheContext);
    }

    async subscribeGeofenceUpdates(c: any) {
        return this.pubSubService.subscribe<{[key: string]: Geofence}>(c, Topic.AccountMapGeofences, [this.accountService.getAccountId(), '+']);
    }

    async subscribeGeofenceEvents(c: any) {
        return (await this.pubSubService.subscribe<AnyBadgeEvent>(c, Topic.AccountPersonBadgeEvent, [this.accountService.getAccountId(), '+']))
            .pipe(filter(x => [EventType.GeofenceEnter, EventType.GeofenceExit].includes(x.payload.type)));
    }

    async subscribeFacilityEvents(c: any) {
        return (await this.pubSubService.subscribe<AnyBadgeEvent>(c, Topic.AccountPersonBadgeEvent, [this.accountService.getAccountId(), '+']))
            .pipe(filter(x => [EventType.FacilityEnter, EventType.FacilityExit].includes(x.payload.type)));
    }

    // returns split line geofence event string for tables
    public getGeofenceEventString(eventType: EventType.GeofenceEnter | EventType.GeofenceExit | EventType.GeofenceDisconnect, name: string): string {
        const prefix = () => {
            switch (eventType) {
                case EventType.GeofenceEnter : return `${this.translationService.getImmediate('shared.geofence.enter')} <br />`;
                case EventType.GeofenceExit : return `${this.translationService.getImmediate('shared.geofence.exit')} <br />`;
                case EventType.GeofenceDisconnect : return `${this.translationService.getImmediate('shared.geofence.disconnect')} <br />`;
            }
        };
        return `${prefix()} ${name}`;
    }

    transformToBoxTrees(geofences: Geofence[]) {
        const trees: BoxTree<Fence, any> = new BoxTree<Fence, any>((obj) => obj.points, 0);
        geofences.forEach(l => {
            const obj: Fence = {
                id: l.id,
                points: l.vertices,
            };
            trees.add(obj);
        });
        return trees;
    }
}
