<div class="form">
    <form [formGroup]="form">
        <div class="form-tab-content">
            <div [formGroup]="form">
                <div>
                    <app-folder-tree-select
                        class="col-xs-12"
                        label="table.parent-folder"
                        formControlName="parentId"
                        [folderType]="folderType"
                        [facilityId]="facilityId"
                        [permission]="permission"
                        [selectionMax]="1"
                        required
                        [autofocus]="true"
                    ></app-folder-tree-select>
                </div>
            </div>
        </div>
        <div class="form-footer actions">
            <app-form-buttons
                [submitDisabled]="!form.valid"
                (submitOutput)="handleSubmit()"
                (cancelOutput)="handleCancelClick()"
            ></app-form-buttons>
        </div>
    </form>
</div>
