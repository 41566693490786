export interface Fence {id: string; points: Array<number[]>; }
// 15m buffer in degrees
const BUFFER = 10 * 360 / 4.0075e7;

export function intersectsPolygon(latlng: {lat: number, lng: number}[], bounds: number[][]) {
    const points = latlng.map(p => [p.lat, p.lng]);
    const box: Box = [bounds[0][1], bounds[0][0], bounds[1][1], bounds[1][0]];
    return points.some(p => intersect(box, p))
        || insidePolygon(points, [0.5 * (bounds[0][0] + bounds[1][0]), 0.5 * (bounds[0][1] + bounds[1][1])])
        || points.some((p, i) => intersectsLine(bounds, p, points[(i + 1) % points.length]));
}

export function insidePolygon(points: Array<number[]>, point: number[], strict: boolean = false) {
    if (!point || !points) return false;
    let cross = false;
    const x = point[0];
    const y = point[1];
    const r = BUFFER;
    const ly = latitudeScale(y);
    for (let i = 0; i < points.length; i++) {
        const x1 = points[i][0];
        const y1 = points[i][1];
        const x2 = points[i === points.length - 1 ? 0 : i + 1][0];
        const y2 = points[i === points.length - 1 ? 0 : i + 1][1];

        if (!strict) {
            if (((x - x1) * (x - x1) * ly * ly + (y - y1) * (y - y1)) < r * r) return true;

            const d = (y2 - y1) * y + (x2 - x1) * x;
            const d1 = (y2 - y1) * y1 + (x2 - x1) * x1;
            const d2 = (y2 - y1) * y2 + (x2 - x1) * x2;
            if ((d > d1 || d > d2) && (d < d1 || d < d2)) {
                const ni = 1.0 / ((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1));
                const ry = y1 + (y2 - y1) * (d - d1) * ni;
                const rx = x1 + (x2 - x1) * (d - d1) * ni;
                if (((x - rx) * (x - rx) * ly * ly + (y - ry) * (y - ry)) < r * r) return true;
            }
        }

        if (y1 === y2) continue;

        const t = (y - y1) / (y2 - y1);
        if (t < 0 || t >= 1) continue;

        const nx = (x2 - x1) * t + x1;
        if (nx >= x) cross = !cross;
    }
    return cross;
}

export type Box = [number, number, number, number];

const CHILD_COUNT = 4;
interface BoxTreeEntry<T> {
    children: BoxTreeEntry<T>[];
    object: T;
    box: Box;
}

function latitudeScale(y) {
    return Math.cos(Math.min(89, Math.abs(y)) * Math.PI / 180);
}

function intersectsLine(box: number[][], p1: number[], p2: number[]) {
    for (let i = 0; i < 2; i++) {
        for (let j = 0; j < 2; j++) {
            if ((p1[i] < box[j][i]) !== (p2[i] < box[j][i])) {
                const i1 = p1[1 - i] + (p2[1 - i] - p1[1 - i]) * (box[j][i] - p1[i]) / (p2[i] - p1[i]);
                if (i1 >= box[0][1 - i] && i1 <= box[1][1 - i]) return true;
            }
        }
    }
    return false;
}

function contains(box: Box, inside: Box) {
    return inside[0] >= box[0] && inside[1] >= box[1] && inside[2] <= box[2] && inside[3] <= box[3];
}

function intersect(box: Box, point: number[]) {
    return point[0] >= box[0] && point[0] <= box[2] && point[1] >= box[1] && point[1] <= box[3];
}

function union(box: Box, inside: Box): Box {
    return [Math.min(box[0], inside[0]), Math.min(box[1], inside[1]), Math.max(box[2], inside[2]), Math.max(box[3], inside[3])];
}

function area(box: Box) {
    return (box[2] - box[0]) * (box[3] - box[1]);
}


function fromPoints(points: number[][], buffer: number): Box {
    const box: Box = [
        points[0][0] - buffer / latitudeScale(points[0][1]),
        points[0][1] - buffer,
        points[0][0] + buffer / latitudeScale(points[0][1]),
        points[0][1] + buffer,
    ];
    for (let i = 1; i < points.length; i++) {
        box[0] = Math.min(box[0], points[i][0] - buffer / latitudeScale(points[i][1]));
        box[1] = Math.min(box[1], points[i][1] - buffer);
        box[2] = Math.max(box[2], points[i][0] + buffer / latitudeScale(points[i][1]));
        box[3] = Math.max(box[3], points[i][1] + buffer);
    }
    return box;
}

export function pointDistance(p1: number[], p2: number[]) {
    const p1x = p1[0] / latitudeScale(p1[1]);
    const p1y = p1[1];
    const p2x = p2[0] / latitudeScale(p2[1]);
    const p2y = p2[1];
    return Math.sqrt(
        (p1x - p2x) * (p1x - p2x) + (p1y - p2y) * (p1y - p2y),
    ) * 4.0075e7 / 360;
}

export class BoxTree<T extends { id?: string }, S extends { location?: number[]; }> {
    root: BoxTreeEntry<T>;

    constructor(private points: (obj: T) => Array<number[]>, private buffer: number = BUFFER) {}

    remove(object: T) {
        if (this.root) {
            const box = fromPoints(this.points(object), this.buffer);
            return this.removeBox(object, box, this.root);
        }
        return false;
    }

    private removeBox(object: T, box: Box, current: BoxTreeEntry<T>) {
        if (current.object === object || current.object && current.object['id'] === object['id']) {
            current.object = null;
            return true;
        }
        if (!contains(current.box, box)) return false;
        for (let i = 0; i < current.children.length; i++) {
            if (this.removeBox(object, box, current.children[i])) {
                return true;
            }
        }
        return false;
    }

    add(object: T) {
        const points = this.points(object);

        const box = fromPoints(points, this.buffer);

        let current = this.root;
        while (current) {
            if (!contains(current.box, box)) {
                if (current.object) {
                    current.children.push({ children: [], object: current.object, box: current.box });
                    current.object = null;
                }
                current.box = union(current.box, box);
            }
            if (current.children.length < CHILD_COUNT) {
                current.children.push({ children: [], object, box });
                return;
            }

            let best = -1;
            let bestArea = 0;
            for (let i = 0; i < current.children.length; i++) {
                if (contains(current.children[i].box, box)) {
                    best = i;
                    bestArea = 0;
                    break;
                } else {
                    const newArea = area(union(current.children[i].box, box)) - area(current.children[i].box);
                    if (best === -1 || newArea < bestArea) {
                        best = i;
                        bestArea = newArea;
                    }
                }
            }
            current = current.children[best];
        }
        this.root = {
            children: [],
            object,
            box,
        };
    }

    find(after: S[], inside: { [id: string]: S[] }, outside?: { [id: string]: S[] }) {
        if (this.root) {
            this.findBox(after, inside, outside, this.root);
        }
    }

    private findBox(after: S[], inside: { [id: string]: S[] }, outside: { [id: string]: S[] }, box: BoxTreeEntry<T>) {
        const found = after.filter(x => x?.location && intersect(box.box, x.location));
        if (!found.length) return;

        if (box.object) {
            const points = this.points(box.object);
            const insideFound = inside ? found.filter(x => insidePolygon(points, x.location, true)) : [];
            const outsideFound = outside ? found.filter(x => insidePolygon(points, x.location)) : [];
            if (insideFound.length) {
                if (!inside[box.object.id]) inside[box.object.id] = [];
                inside[box.object.id].push(...insideFound);
            }
            if (outsideFound.length) {
                if (!outside[box.object.id]) outside[box.object.id] = [];
                outside[box.object.id].push(...insideFound);
            }
        }
        for (let i = 0; i < box.children.length; i++) {
            this.findBox(found, inside, outside, box.children[i]);
        }
    }

    findQuick(location: number[]) {
        if (this.root) {
            return this.findQuickBox(location, this.root);
        }
        return false;
    }

    private findQuickBox(location: number[], box: BoxTreeEntry<T>) {
        if (!intersect(box.box, location)) return;

        if (box.object) {
            const points = this.points(box.object);
            if (insidePolygon(points, location, true)) return true;
        }
        for (let i = 0; i < box.children.length; i++) {
            if (this.findQuickBox(location, box.children[i])) return true;
        }
    }
}

export function hashLocation(location: number[], diameter: number = 2.5) {
    const ratio = 4.0075e7 / (360 * diameter);
    const scale = latitudeScale(location[1]);
    const index0 = Math.floor(location[0] * ratio * scale);
    const index1 = Math.floor(location[1] * ratio);
    const hash = `${index0}-${index1}`;
    return { hash, location: [(index0 + 0.5) / (ratio * scale), (index1 + 0.5) / ratio] };
}
