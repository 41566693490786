<div class="wrapper" 
    [ngClass]="{
        'small': size === 'small',
        'large': size === 'large'
    }"
    (click)="click()"
>
    <div class="avatar" [ngClass]="{'light': lightTheme}">
        <app-avatar [avatarInput]="avatarInput"></app-avatar>
    </div>

    <div class="content">
        <div class="content-row">
            <p class="name">{{person.fullName ? person.fullName : ('generics.unknown' | translate)}}</p>
            <div *ngIf="userProfile?.personId !== person?.id" class="mLa" (click)="handleChatPress($event)">
                <app-icon [faIcon]="'fas fa-comment-dots'" [fontSize]="'18px'"></app-icon>
            </div>
        </div>

        <div class="content-row">
            <p *ngIf="person?.company?.name" class="company-name">{{person.company.name}}</p>
            <div class="mLa company-logo" *ngIf="person?.company?.logo">
                <img [src]="person?.company?.logo">
            </div>
        </div>
    </div>
</div>