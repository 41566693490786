import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss'],
})
export class FilterHeaderComponent {
    @Input() titleKey: string;
    @Input() includeBackClick: boolean = true;
    @Output() backClick: EventEmitter<void> = new EventEmitter();

    constructor() { }
}
