import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

import { AlertService } from './alert.service';
import { CacheContext, HttpService } from './http.service';

import { Tag, TagNameMap } from '../models/tag.model';

import { Utils } from '../utils/utils';

@Injectable({ providedIn: 'root' })
export class TagService {

    constructor(
        private httpService: HttpService,
        private alertsService: AlertService,
    ) { }

    private readonly url: string = '/core/tags';
    private readonly cacheContext: CacheContext = { collection: 'Tags', maxAge: 1800000 };

    add(component, tag: Tag) {
        return this.httpService.post<Tag>(component, this.url, tag, this.cacheContext);
    }

    async getAll(component) {
        try {
            let data = cloneDeep(await this.httpService.get<Tag[]>(component, this.url, null, this.cacheContext));
            data = data || [];
            return Utils.sortAlphabetical(data, (item) => item.name);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.TAGS.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async getAllAssignable(component) {
        try {
            let data = cloneDeep(await this.httpService.get<Tag[]>(component, this.url, null, this.cacheContext));
            data = data || [];
            data = data.filter(x => !x.unassignable);
            return Utils.sortAlphabetical(data, (item) => item.name);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.TAGS.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    /**
     * @returns Map of id and tag name
     */
    async getAllMap(component) {
        const tagsMap: TagNameMap = {};
        (await this.getAll(component)).forEach(x => tagsMap[x.id] = x.name);
        return tagsMap;
    }
}
