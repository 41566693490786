import { Readable } from 'readable-stream';

// A Readable Stream with an iterator used as its data source.
// Adapted from ./node_modules/mqtt/lib/store.js:createStream()
export class ReadableIterator extends Readable {
    private isDestroyed = false;

    public constructor(private iterator: IterableIterator<unknown>) {
        super({ objectMode: true });
    }

    public _read = () => {
        if (this.isDestroyed) {
            this.push(null);
        } else {
            const next = this.iterator.next();
            this.push(next.done ? null : next.value);
        }
    };

    public destroy = (_error?: Error): this => {
        if (this.isDestroyed) return this;
        this.isDestroyed = true;
        setImmediate(() => this.emit('close'));
        return this;
    };
}
