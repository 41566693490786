import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { CallSystemMessageType, VideoCallingAccessToken } from '../acs.service';

interface CallingSystemMessageRequest {
    type: CallSystemMessageType;
    forOtherUser?: boolean;
}

interface MissedPageResponse {
    callId: string;
    callerId: string;
    calleeId: string | null;
}

@Injectable({
    providedIn: 'root',
})
export class AcsHttpService {
    constructor(private readonly httpService: HttpService) {}

    async getAccessToken(component: any) {
        return this.httpService.get<VideoCallingAccessToken>(component, '/acs/access-token');
    }

    async getUserConsentUrl(component: any, userId: string, tenantId: string) {
        return await this.httpService.get<string>(component, `/teams/consent/user-url?userId=${userId}&tenantId=${tenantId}`);
    }

    async hasAdToken(component: any) {
        return this.httpService.get<boolean>(component, '/acs/has-ad-token');
    }

    /** Returns a list of Teams identity for other people in the channel. */
    async getChannelRecipientsIds(component: any, channelId: string) {
        return await this.httpService.get<string[]>(component, `/core/channels/${channelId}/call-recipients`);
    }

    async getUserIdentity(component: any, userId: string) {
        // TODO: switch to single string
        return await this.httpService.get<string[]>(component, `/acs/identity/${userId}`);
    }

    async createCallSystemMessage(component: any, channelId: string, request: CallingSystemMessageRequest) {
        return await this.httpService.post(component, `/core/channels/${channelId}/calling-system-messages`, request);
    }

    async createMissedCallNotification(component: any, body: MissedPageResponse) {
        return await this.httpService.put(component, '/acs/missed-call-notification', body);
    }
}
