export interface IdentifyTraits {
    email: string;
    name: string;
    accountId: string;
}

export enum StProperty {
    accountId = 'accountId',
    action = 'action',
    component = 'component',
    count = 'count',
    dimensions = 'dimensions',
    endpoint = 'endpoint',
    errorData = 'errorData',
    id = 'id',
    method = 'method',
    object = 'object',
    page = 'page',
    query = 'query',
    source = 'source',
    type = 'type',
    unit = 'unit',
    version = 'version',
    visible = 'visible',
}

export type StProperties = { [key in StProperty]?: any };
export const allowedKeys = new RegExp((Object.keys(StProperty).join('|')), 'i');

export enum StObject {
    AccountSwitcher = 'AccountSwitcher',
    AnonymousUser = 'AnonymousUser',
    CallCamera = 'CallCamera',
    CallAudio = 'CallAudio',
    Channel = 'Channel',
    ChannelMessage = 'ChannelMessage',
    ChannelNotificationSettings = 'ChannelNotificationSettings',
    ChannelTagFilter = 'ChannelTagFilter',
    MapPlaybackTimeSelection = 'MapPlaybackTimeSelection',
    MessageAttachment = 'MessageAttachment',
    Tag = 'Tag',
    UserProfile = 'UserProfile',
    VoiceCall = 'VoiceCall',
    VideoCall = 'VideoCall',
    WilmaDetailPtt = 'WilmaDetailPtt',
    WilmaDetailSiren = 'WilmaDetailSiren',
    WilmaDetailBan = 'WilmaDetailBan',
    WilmaWidgetExpand = 'WilmaWidgetExpand',
    WilmaWidgetMap = 'WilmaWidgetMap',
    WilmaWidgetSiren = 'WilmaWidgetSiren',
    UserAvatar = 'UserAvatar',
    ApiKey = 'ApiKey',
    Beacon = 'Beacon',
    Bulk = 'Bulk',
    Company = 'Company',
    Dashboard = 'Dashboard',
    DashboardWidget = 'DashboardWidget',
    Facility = 'Facility',
    Folder = 'Folder',
    Form = 'Form',
    Geofence = 'Geofence',
    GeofenceType = 'GeofenceType',
    FloorPlan = 'FloorPlan',
    Meeting = 'Meeting',
    PermissionGroup = 'PermissionGroup',
    Person = 'Person',
    Structure = 'Structure',
    TableBulkAction = 'TableBulkAction',
    TableRowAction = 'TableRowAction',
    WifiRouter = 'WifiRouter',
    Workflow = 'Workflow',
    Wrangler = 'Wrangler',
    Item = 'Item',
}

export enum StAction {
    Admitted = 'Admitted',
    Canceled = 'Canceled',
    Cleared = 'Cleared',
    Clicked = 'Clicked',
    Copied = 'Copied',
    Created = 'Created',
    Deleted = 'Deleted',
    Disabled = 'Disabled',
    Edited = 'Edited',
    Enabled = 'Enabled',
    Ended = 'End',
    Joined = 'Joined',
    Left = 'Left',
    Messaged = 'Messaged',
    Moved = 'Moved',
    Rejected = 'Rejected',
    Requested = 'Requested',
    Removed = 'Removed',
    Resized = 'Resized',
    Set = 'Set',
    Started = 'Start',
    Subscribed = 'Subscribed',
    Toggled = 'Toggled',
    Unsubscribed = 'Unsubscribed',
    Updated = 'Updated',
    Uploaded = 'Uploaded',
    Exported = 'Exported',
    Imported = 'Imported',
}
