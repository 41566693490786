
<div class="flexRow aiC">
    <div class="input-container"
        appClickOutside
        (clickOutside)="hideResultsList()"
    >
        <app-input class="w100p"
            [placeholder]="'search.search'"
            [value]="searchQuery"
            [light]="true"
            (inputFocus)="handleSearch(searchQuery)"
            (valueChanged)="handleSearch($event)"
        ></app-input>    
    
        <div class="results-list" *ngIf="showResults">
            <app-entity-list
                [list]="searchResults"
                [maxHeight]="300"
                (itemClick)="handleItemClick($event)"
            ></app-entity-list>
        </div>
    </div>
    
    <div class="pL15" *ngIf="filteredMarkerCount">
        <p>{{'map.showing-out-of' | translate: {filterCount: filteredMarkerCount.showing, totalCount: filteredMarkerCount.total} }}</p>
    </div>
</div>
