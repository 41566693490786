import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ChangeRecord } from './indexed-storage';

export class StorageEmitter<T extends { id: string }> {
    private listeners = new Map<string, Set<(...args: any[]) => any>>();
    private map: { [id: string]: T } = {};
    loaded$ = new BehaviorSubject(false);

    wait() {
        return this.loaded$.pipe(takeWhile(x => !x)).toPromise();
    }

    on(event: 'load', fn: (arg: T[]) => any): this;
    on(event: 'update', fn: (arg: ChangeRecord<T>) => any): this;
    on(event: 'delete', fn: (arg: T) => any): this;
    on(event: 'request-more', fn: () => any): this;
    on(event: 'more-loaded', fn: (arg: T[]) => any): this;
    on(event: 'no-more', fn: () => any): this;
    on(event: 'close', fn: () => any): this;
    on(event: string, fn: (...args: any[]) => any): this {
        if (!this.listeners.has(event)) this.listeners.set(event, new Set());
        this.listeners.get(event)?.add(fn);
        if (event === 'load' && this.loaded$.value) this.emit('load', Object.values(this.map));
        return this;
    }

    off(event: string, fn: (...args: any[]) => any): this {
        this.listeners.get(event)?.delete(fn);
        return this;
    }

    emit(event: string, ...args: any[]) {
        this.listeners.get(event)?.forEach(x => x(...args));
    }

    loading() {
        this.loaded$.next(false);
    }

    load(records: T[], more = false) {
        if (!more) this.map = {};
        records.forEach(x => (this.map[x.id] = x));
        this.loaded$.next(true);
        this.emit(more ? 'more-loaded' : 'load', records);
    }

    next(value: ChangeRecord<T>) {
        this.map[value.value.id] = value.value;
        this.emit('update', value);
    }

    deleted(id: string) {
        const existing = this.map[id];
        if (existing) {
            delete this.map[id];
            this.emit('delete', existing);
        }
    }

    more() {
        return this.emit('request-more');
    }

    close() {
        this.listeners.clear();
        this.emit('close');
    }
}
