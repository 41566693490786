import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { DateRange } from 'weavix-shared/models/dvr.model';
import { MapFilterResult } from 'weavix-shared/models/weavix-map.model';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
@AutoUnsubscribe()
@Component({
    selector: 'app-weavix-map-filter',
    templateUrl: './weavix-map-filter.component.html',
    styleUrls: ['./weavix-map-filter.component.scss'],
})
export class WeavixMapFilterComponent implements OnInit {
    @Input() title: string = 'generics.filters';
    @Input() clearText: string = 'map.controls.clear-filters';
    @Input() filterResults: {[key: string]: MapFilterResult} = {};
    @Input() dateRange: DateRange;
    @Input() hideDateRange: boolean = false;
    @Input() isDateRangeDisabled: boolean = false;
    @Input() multiCategorySelect = false;
    @Input() ignoreViewSubscriptions: boolean = false;
    @Input() sortFn: (values: MapFilterResult[]) => MapFilterResult[];
    @Output() dateRangeChanged = new EventEmitter<DateRange>();

    loading: boolean = false;
    lightTheme: boolean;


    get filtersEmpty() {
        return !Object.values(this.filterResults).some(x => x.selected);
    }

    get filterResultsSorted() {
        // TODO: This is being called WAYYY too many times and should be changed to setting the value within the ngOnChanges method...
        // Problem for future you as there is an issue with the rendering of the method with that approach...
        const results = this.sortFn ? this.sortFn(Object.values(this.filterResults)) : Utils.sortAlphabetical(Object.values(this.filterResults), res => res?.name?.toLowerCase());
        // filtered options with no children should always be at the bottom of the list
        results.map((elem, index) => {
            if (elem.children) return;
            results.splice(index, 1);
            results.push(elem);
        });
        return results;
    }

    getSortedChildren(result: MapFilterResult) {
        return this.sortFn ? this.sortFn(Object.values(result.children ?? {})) : Utils.sortAlphabetical(Object.values(result.children ?? {}), res => res.name?.toLocaleLowerCase());
    }

    constructor() {}

    ngOnInit() {
        this.loading = true;
        this.lightTheme = ThemeService.getLightTheme();
        this.loading = false;
    }

    handleClearFilters() {
        this.clearAllSelections({ children: this.filterResults } as any);
    }

    deselectCategory(cat: MapFilterResult, ignore?, clearMultiSelections: boolean = false): void {
        if (cat.children) {
            cat.childrenSelected = (cat.multiselect && !clearMultiSelections) && Object.keys(cat?.children ?? {}).some(k => cat?.children[k].selected);
            Object.values(cat.children).forEach(subCat => {
                if (subCat === ignore || !subCat.selected) return;
                if (cat.multiselect !== true || clearMultiSelections) subCat.selected = false;
                subCat.setSelected?.(subCat.selected);
                subCat.hidden = false;
                this.deselectCategory(subCat);
            });
        }
    }

    /*-- clears all selections for single and multi selection--*/
    clearAllSelections(cat: MapFilterResult): void {
        if (cat?.children) {
            cat.childrenSelected = false;
            Object.values(cat.children).forEach(subCat => {
                if (!subCat.selected && !subCat.childrenSelected) return;
                subCat.selected = false;
                subCat.setSelected?.(subCat.selected, true);
                subCat.hidden = false;
                this.clearAllSelections(subCat);
            });
        }
    }

    handleRowClick(parent: MapFilterResult, cat: MapFilterResult, click: boolean, depth: number): void {
        if (!cat) return;

        if (!this.multiCategorySelect) this.deselectCategory(parent, cat);
        if (depth > 0 && parent.multiselect !== true) this.deselectCategory(parent, cat);
        if (cat.selected || click) this.deselectCategory(cat);
        cat.selected = click ? true : !cat.selected;
        cat.setSelected?.(cat.selected, true, click);
    }

    onDateRangeChanged(value: DateRange) {
        this.dateRangeChanged.emit(value);
    }
}
