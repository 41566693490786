import { IndexedStorage } from './indexed-storage';
import { StorageEmitter } from './storage-emitter';
import { debounce } from 'lodash';

export interface QueryStorage<T> {
    partition?: string;
    index?: (index: string) => any;
    limit?: number;
    filter?: (a: T) => any;
}

export class QueryableStorage<T extends { id: string }> {
    constructor(public index: IndexedStorage<T>) {}

    query(query: QueryStorage<T> = {}): StorageEmitter<T> {
        const match = (x: T, index: string, partitions?: string[]) => {
            // if (query.index && !query.index(index)) return false;
            if (query.partition && !partitions?.includes(query.partition)) return false;
            if (query.filter && !query.filter(x)) return false;
            return true;
        };

        const emitter = new StorageEmitter<T>();
        const debounced = debounce(async () => {
            await this.pull(query, emitter);
        }, 100);
        const subs = [
            this.index.changed$.subscribe(value => {
                if (value.delete) emitter.deleted(value.value.id);
                else if (match(value.value, value.index, value.partitions)) {
                    emitter.next(value);
                }
            }),
            this.index.partitionChanged$.subscribe(() => {
                emitter.loading();
                debounced();
            }),
        ];
        emitter.on('close', () => {
            subs.forEach(x => x.unsubscribe());
        });

        // purposefully not awaiting. results are retrieved though emitter.
        this.pull(query, emitter);

        return emitter;
    }

    private async pull(query: QueryStorage<T>, emitter: StorageEmitter<T>) {
        const list: T[] = [];

        const index = await this.index.getIndex();
        const partitions = await this.index.getPartitionsByIds();

        const filterValues = (values: T[]) => {
            if (!query.filter) return values;
            return values.filter(v => query.filter!(v));
        };

        let lastIndex = 0;
        const filterKeys = async (keys: string[]) => {
            let remaining = query.limit || keys.length;
            const results = [];
            while (remaining && lastIndex < keys.length) {
                const end = Math.min(keys.length, lastIndex + remaining);
                const keyValues = await this.index.getAll(keys.slice(lastIndex, end));
                const found = filterValues(keyValues);
                results.push(...found);
                remaining -= found.length;
                lastIndex = end;
            }
            return results;
        };

        if (query.index) {
            const keys = Object.keys(index)
                .sort((a, b) => (index[a] < index[b] ? 1 : -1))
                .filter(key => query.index!(index[key]));

            if (query.limit) {
                const refresh = async () => {
                    const remaining = await filterKeys(keys);
                    if (remaining.length) {
                        emitter.load(remaining, true);
                        return true;
                    } else {
                        emitter.emit('no-more');
                    }
                };
                emitter.on('request-more', refresh);
                list.push(...(await filterKeys(keys)));
            } else {
                list.push(...(await filterKeys(keys)));
            }
        } else {
            const values = await filterKeys(
                Object.keys(partitions).filter(key => !query.partition || partitions[key].includes(query.partition)),
            );
            list.push(...values);
        }
        emitter.load(list);
        return list;
    }
}
