<div *ngIf="icon.bgCircle"
    class="bg-circle"
    [ngStyle]="{'backgroundColor': icon.bgCircle.bgColor, 'width': icon.bgCircle.diameter, 'height': icon.bgCircle.diameter}"
>
    <ng-container *ngTemplateOutlet="renderIcon; context: {icon: icon}"></ng-container>
</div>

<ng-container *ngIf="!icon.bgCircle">
    <ng-container *ngTemplateOutlet="renderIcon; context: {icon: icon}"></ng-container>
</ng-container>



<ng-template #renderIcon let-icon="icon">
    <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="!!icon.img">
            <div class="wrapper" [ngStyle]="{'width.px': icon.width, 'height.px': icon.height}" [matTooltip]="icon.tooltip">
                <img [ngClass]="{'w100p h100p': icon.inline === false}" [ngStyle]="{'border-radius': icon.borderRadius}" [src]="icon.img" alt="{{icon.altText}}"/>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="!!icon.svgFile">
            <!-- Using mask to set color on a solid svg -->
            <img *ngIf="icon.color" 
                [ngClass]="{'w100p h100p': icon.inline === false}" 
                [ngStyle]="{
                    'width.px': icon.width,
                    'height.px': icon.height,
                    mask: 'url(' + svgFilePath + ') no-repeat center / contain',
                    '-webkit-mask': 'url(' + svgFilePath + ') no-repeat center / contain',
                    'background-color': icon.color
                }"
                alt="{{icon.altText}}"
                [matTooltip]="icon.tooltip"
            />
            <img *ngIf="!icon.color" [ngClass]="{'w100p h100p': icon.inline === false}" [ngStyle]="{'width.px': icon.width, 'height.px': icon.height}" [src]="svgFilePath" alt="{{icon.altText}}" [matTooltip]="icon.tooltip"/>
        </ng-container>

        <ng-container *ngSwitchCase="!!icon.faIcon || !!icon.fntlIcon">
            <i [ngClass]="{'fa-icon': !!icon.faIcon, 'w100p h100p': icon.inline === false, 'centerIcon' : centered}" [class]="icon.faIcon || icon.fntlIcon" [ngStyle]="{'color': icon.color, 'font-size': icon.fontSize}" [matTooltip]="icon.tooltip"></i>
        </ng-container>

        <ng-container *ngSwitchCase="!!icon.matIcon">
            <mat-icon [inline]="true" [ngStyle]="{'color': icon.color, 'font-size': icon.fontSize}" [matTooltip]="icon.tooltip">{{icon.matIcon}}</mat-icon>
        </ng-container>

        <ng-container *ngSwitchCase="!!icon.flagIcon">
            <span [ngClass]="['flag-icon', 'flag-icon-squared', 'flag-icon-' + icon.flagIcon]"></span>
        </ng-container>
        
    </ng-container>
</ng-template>
