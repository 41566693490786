import { get, sortBy } from 'lodash';

export function sortByString<T>(arr?: T[], sortByLookup?: (item: T) => string): T[] | undefined {
    if (!arr || !sortByLookup) return undefined;
    return sortBy(arr, item => sortByLookup(item)?.toLowerCase());
}

export function sortByDate<T>(sort: T[], datePropOrLookup: string | ((d: T) => Date), desc = false): any[] {
    const getValue = typeof datePropOrLookup === 'function' ? (x: T) => datePropOrLookup(x) : (x: T) => get(x, datePropOrLookup);
    if (desc) return sort.sort((a, b) => new Date(getValue(b)).getTime() - new Date(getValue(a)).getTime());
    return sort.sort((a, b) => new Date(getValue(a)).getTime() - new Date(getValue(b)).getTime());
}

type ValueSort<T> = string | ((v: T) => string);

export function sortAlphabetical<T>(sort: T[], valueSort?: ValueSort<T>) {
    return sort?.sort(alphabeticalSorter(valueSort));
}

function alphabeticalSorter<T>(valueSort?: ValueSort<T>) {
    const collator = new Intl.Collator(undefined, { sensitivity: 'base' });
    const map = new Map<T, string>();
    const getLowerValue =
        typeof valueSort === 'function'
            ? (x: T) => valueSort(x)?.toString() ?? (x as unknown as string)?.toString() ?? ''
            : (x: T) => get(x, valueSort as any)?.toString() ?? (x as unknown as string)?.toString() ?? '';
    return (a: T, b: T) => {
        if (!map.has(a)) map.set(a, getLowerValue(a));
        if (!map.has(b)) map.set(b, getLowerValue(b));
        const aVal: string = map.get(a) ?? '';
        const bVal: string = map.get(b) ?? '';
        return !aVal ? 1 : !bVal ? -1 : collator ? collator.compare(aVal, bVal) : aVal < bVal ? -1 : 1;
    };
}
