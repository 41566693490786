import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';

import { FloorPlan, MapFloorPlan } from '../models/floor-plan.model';
import { PermissionAction } from '../permissions/permissions.model';
import { Utils } from '../utils/utils';
import { FloorPlanService } from './floor-plan.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class FloorPlanStateService extends StateServiceBase {
    floorPlans: Map<string, MapFloorPlan> = new Map();
    structureIdToFloorPlan: Map<string, MapFloorPlan[]> = new Map(); // <StructureId, FloorPlan>
    floorPlans$: Subject<StateUpdate<MapFloorPlan>> = new Subject();

    constructor(
        private floorPlanService: FloorPlanService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)) return;
        this.floorPlans = Utils.toMap(await this.floorPlanService.getAll(c, facilityId, tags));
        this.floorPlans.forEach(fp => { if (fp.structureId) this.updateStructureMap(fp); });
    }

    async stop() {
        super.stop();
        this.floorPlans.clear();
        this.structureIdToFloorPlan.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)) return;

        return (await this.floorPlanService.subscribeFloorPlanUpdates(c)).subscribe(async result => {
            const floorPlanMap = result.payload;
            this.updateFromMap(this.floorPlans, floorPlanMap, this.floorPlans$);
            Object.keys(floorPlanMap).forEach(key => this.updateStructureMap(floorPlanMap[key]));
        });
    }

    private updateStructureMap(fp: FloorPlan): void {
        if (!fp) return;
        const structureFloorplans = this.structureIdToFloorPlan.get(fp.structureId);
        if (structureFloorplans) structureFloorplans.push(fp);
        else this.structureIdToFloorPlan.set(fp.structureId, [fp]);
    }
}

export class CustomFloorPlanStateService extends StateServiceBase {
    structureIdToFloorPlan: Map<string, MapFloorPlan[]> = new Map(); // <StrcutureId, FloorPlan>
    floorPlans: Map<string, MapFloorPlan> = new Map();
    floorPlans$: Subject<StateUpdate<MapFloorPlan>> = new Subject();

    constructor(
        private data: MapFloorPlan[],
        private updates$: Subject<MapFloorPlan[]>,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        this.floorPlans = Utils.toMap(cloneDeep(this.data));
        this.floorPlans.forEach(fp => { if (fp.structureId) this.updateStructureMap(fp); });
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        if (!this.updates$) return;
        return this.updates$.subscribe(async floorPlans => {
            const floorPlanMap = Utils.toObjectMap(floorPlans, (f) => f.id) as {[key: string]: FloorPlan};
            this.updateFromMap(this.floorPlans, floorPlanMap, this.floorPlans$);
            Object.keys(floorPlanMap).forEach(key => this.updateStructureMap(floorPlanMap[key]));
        });
    }

    private updateStructureMap(fp: FloorPlan): void {
        const structureFloorplans = this.structureIdToFloorPlan.get(fp.structureId);
        if (structureFloorplans) structureFloorplans.push(fp);
        else this.structureIdToFloorPlan.set(fp.structureId, [fp]);
    }
}
