export enum WilmaTopic {
    // topics received from a wilma
    WilmaEvent = 'wilma/+/event', // similar to badge updates but for wilma. includes battery level, network status, gas readings
    WilmaImageIndex = 'wilma/+/image/+/+', // when no motion, wilma sends an image every 0.5 second
    WilmaVideoIndex = 'wilma/+/video/+/+', // when there is motion, wilma sends a continuous video in 0.3 second chunks
    WilmaCard = 'wilma/+/card', // when someone scans their nfc card on the wilma to sign in to hole watch

    // topics sent to a wilma
    WilmaAudioStream = 'wilma/+/audio-stream/+', // when someone sends audio to wilma through Console
    WilmaConfinedSpace = 'wilma/+/confined-space', // when someone checks in/out to a hole watch, or takes a gas reading

    // when someone checks in/out to a hole watch, or takes a gas reading. it's the same as WilmaConfinedSpace above, but
    // this topic is sent to Console/mobile
    AccountConfinedSpaceEvent = 'account/+/confined-space/+/event',

    // wilma client topics
    AccountWilmaCameraVideo = 'account/+/wilma/+/camera/+/video/+',
    AccountWilmaReading = 'account/+/wilma/+/reading', // gas readings. used by mobile

    /*
        AccountExport is used by Console to export a chunk of wilma video for the user to download.
        The user initiates the export through an HTTP request to /track/wilma/export, and then
        Console listens to progress updates on this topic. When the video segments have been assembled
        into a single file, that file URI is published to this same topic.

        The process can be interrupted by posting a message to AccountExportCancel.
    */
    AccountExport = 'account/+/export/+', // wilma
    AccountExportCancel = 'account/+/export/+/cancel', // wilma

    /*
        AccountWilmaImageDetails has the raw base64 encoded image, and it sends a JSON object that includes the data, the
        date the image was captured, and the name of the image.

        AccountWilmaThumbnailDetails is the same thing, but for thumbnails.
    */
    AccountWilmaImageDetails = 'account/+/wilma/+/image-details/+',
    AccountWilmaThumbnailDetails = 'account/+/wilma/+/thumbnail-details/+',

    /*
        Used to get the latest wilma entity from the database via MQTT instead of the REST API.
        This is essentially the same as GET /track/wilma/{id}
    */
    WilmaResponse = 'wilma/+/response',
    WilmaRequest = 'wilma/+/request',

    // not used anymore
    WilmaImage = 'wilma/+/image',
    WilmaCardResponse = 'wilma/+/card-response', // not actually published anywhere, even though it's subscribed to in wilma python code
    AccountGasReading = 'account/+/gas/+/reading',

    BadgeGasRequest = 'badge/+/gas/request',
    BadgeGasResponse = 'badge/+/gas/response',
}
