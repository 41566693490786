import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'weavix-shared/services/account.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-core-template',
    templateUrl: './core-template.component.html',
    styleUrls: ['./core-template.component.scss'],
})
export class CoreTemplateComponent implements OnInit {
    constructor(private accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    private readonly DEFAULT_ROUTE = 'f';

    async ngOnInit() {
        try {
            await this.accountService.connect();
            const url = `${this.accountService.getAccountRoute()}/${this.route.snapshot.url.join('/') || this.DEFAULT_ROUTE}`;
            const queryParams = this.route.snapshot.queryParams;
            this.router.navigate([url], { replaceUrl: true, queryParams });
        } catch (e) {
            console.error('navigating to authorize because connect error', e);
            this.router.navigate(['login', 'authorize']);
        }
    }
}
