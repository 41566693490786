import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { merge } from 'lodash';

import { AppIcon, SvgIcon } from 'weavix-shared/models/icon.model';

import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
    @Input() svgFile: string | SvgIcon;
    @Input() faIcon: string;
    @Input() matIcon: string;
    @Input() img: string;
    @Input() flagIcon: string;
    @Input() color: string;
    @Input() altText: string;
    @Input() fontSize: string;
    @Input() width: string;
    @Input() height: string;
    @Input() borderRadius: string;
    @Input() inline: boolean;
    @Input() tooltip: string;
    @Input() centered: boolean = false;

    @Input() icon: AppIcon = {};

    svgFilePath: string;

    private readonly defaultIcon: Partial<AppIcon> = { inline: false };
    private readonly svgIconPath: string = '/assets/img/svg-icons';

    ngOnChanges(changes: SimpleChanges) {
        this.icon = merge({ ...this.defaultIcon }, this.icon,
                            Object.keys(changes).filter(x => x !== 'icon').reduce((a, b) => { a[b] = changes[b].currentValue || null; return a; }, {}));
        if (this.icon.svgFile) {
            this.svgFilePath = Object.values(SvgIcon).includes(this.icon.svgFile as SvgIcon) ? `${this.svgIconPath}/${this.icon.svgFile}.svg` : this.icon.svgFile;
        }
    }
}
