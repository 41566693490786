<div class="chart-legend-container">
    <div class="chart-legend-item"
        *ngFor="let li of legendData"
        [ngClass]="{
            'selected': selectable && li?.selected, 
            'cPointer': selectable
        }"
        (click)="handleItemSelection(li)"
    >
        <div class="dot" [ngStyle]="{'background-color': li.color}"></div>
        <div class="label">
            <p class="bold">{{li.name}}</p>
            <p *ngIf="li.value">{{li.value.display ? li.value.display : li.value.actual }}</p>
        </div>
    </div>
</div>
