<div class="container">
    <p style="text-align: center;">Location Details</p>
    <hr>
    <div class="device">
        <div style="display:flex">
            <div *ngIf="person.badge?.locationType !== 'wisp' && person.badge?.deviceType === 'walt'" class="image-icon pad"><i style="font-size: 20px;"
                    class="icon-walt"></i></div>
            <div *ngIf="person.badge?.locationType !== 'wisp' && person.badge?.deviceType !== 'walt'" class="image-icon pad">
                <app-icon matTooltip="{{person.badge?.deviceId}}"
                    style="font-size: 20px; padding:5px" class="fas fa-mobile-iphone"></app-icon>
            </div>
            <div *ngIf="person.badge?.locationType === 'wisp'" class="image-icon pad">
                <app-icon matTooltip="{{person.badge?.deviceId}}"
                    style="font-size: 20px; padding:5px" class="fas fa-tag"></app-icon>
            </div>

            <div class="pad">
                <p matTooltip="{{person.badge?.locationDate | date:'short'}}"
                    [ngStyle]="{'color': (isLocationDelayed()) ? '#ffa81d' : 'white'}">
                    <strong>{{'walt.last-updated' | translate}}:</strong> {{person.badge?.locationDate | date:'mediumTime'}}
                </p>
                <p *ngIf="person.badge?.locationType !== 'wisp' && person.badge?.deviceType === 'walt'">{{person.badge?.deviceId}}</p>
                <p *ngIf="person.badge?.locationType === 'wisp' || person.badge?.deviceType !== 'walt'">{{getDisplayDeviceType()}}</p>
            </div>
        </div>
        <br>
        <div *ngIf="person.badge?.locationType !== 'wisp'">
            <p><strong>{{'walt.calculated-by' | translate}}</strong>
                {{getDisplayLocationType(person.badge?.locationDetails?.method)}}</p>
            <div *ngFor="let beacon of person.badge?.locationDetails?.contributors">
                <div style="display: flex; padding-left: 10px;" class="location-contributor">
                    <i class="{{getContributorIcon(beacon.type)}}"
                        style="font-size: 10px; padding-top: 2px; padding-right: 5px;" aria-hidden="true"></i>
                    <p matTooltip="{{beacon.id}}">{{beacon.name ?? beacon.id}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="person.badge?.locationType !== 'wisp'">
            <br>
            <p><strong>{{'walt.accuracy' | translate}}</strong> {{getLocationAccuracy()}}</p>
        </div>
        <div>
            <p>{{getLocationPermissions()}}</p>
        </div>
    </div>
</div>