
<router-outlet></router-outlet>

<ng-container *ngIf="appLoading !== undefined">
    <div class="loading-overlay z600" *ngIf="appLoading === true">
        <app-logo-spinner [size]="100" [slow]="false"></app-logo-spinner>
    </div>
</ng-container>

<app-modal [modalInput]="pwaModal" *ngIf="pwaModal" (modalCloseOutput)="pwaClose($event)">
    <img src="/assets/img/logo-mobile.png" />
</app-modal>

<app-modal [modalInput]="permissionModal" *ngIf="permissionModal" (modalCloseOutput)="permissionClose($event)">
    <p class="instructions-error" innerHTML="{{ 'meeting-join.denied-permissions' | translate }}"></p>
    <p class="instructions-details" *ngIf="browserError" innerHTML="{{ browserError | translate }}"></p>
</app-modal>
