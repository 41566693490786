<ng-container *ngIf="result" class="dashboard">

    <div *ngIf="!result.hidden " class="res-item"
        [ngClass]="{'selected': result.selected, 'disabled': result?.disabled && result?.disabled.call(result)}"
        (click)="handleSelectClick(result)">

        <div class="res-item-icon">
            <app-icon [ngClass]="{'light': lightTheme}"
                *ngIf="(result?.children | keyvalue)?.length && !result.clickAction && !result.depth"
                [faIcon]="result.selected ? 'fa fa-minus' : 'fa fa-plus'"></app-icon>
        </div>
        
        <app-icon *ngIf="result.icon" class="res-item-cat-icon mx-2" [ngClass]="{'light': lightTheme}" [icon]="icon"></app-icon>

        <div class="res-item-text" [ngClass]="{'light': lightTheme}">{{result.name}}</div>

        <div class="res-item-appendix" [ngClass]="{'light': lightTheme}" *ngIf="result?.appendixText">
            <div *ngIf="result?.appendixText" class="res-item-text w60" [ngClass]="{'light': lightTheme}">
                <app-icon *ngIf="result?.appendixIcon" [icon]="result.appendixIcon"></app-icon>
                <div class="value flexGrow1">{{result.appendixText.call(result)}}</div>
            </div>
        </div>
    </div>

</ng-container>
