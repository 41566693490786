import { debounce } from 'lodash';
import { Observable, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { WebNativeStorage } from './sync/web-native-storage';

export class LazyStorage<T> {
    private static storage = new WebNativeStorage('lazy-storage');
    private static write$?: Observable<any>;
    private writeSubscription?: Subscription;
    private cache?: { [key: string]: T };
    reset = debounce(() => {
        this.cache = undefined;
    }, 15000);

    constructor(public key: string) {
    }

    clear() {
        this.cache = undefined;
        LazyStorage.storage.setMap(this.key, {});
    }

    async map() {
        if (!this.cache) {
            const data = await LazyStorage.storage.getMap(this.key);
            this.cache = (data || {}) as { [key: string]: T };
        }
        this.reset();
        return this.cache;
    }

    async get(key: string) {
        return (await this.map())[key];
    }

    async set(key: string, value: T) {
        (await this.map())[key] = value;
        this.write();
    }

    async remove(key: string) {
        delete (await this.map())[key];
        this.write();
    }

    // Ensures all lazy storage gets flushed in the order they were written to avoid data inconsistencies
    private write() {
        if (!this.writeSubscription) {
            if (!LazyStorage.write$) {
                LazyStorage.write$ = timer(250).pipe(take(1));
                LazyStorage.write$.subscribe(() => (LazyStorage.write$ = undefined));
            }
            this.writeSubscription = LazyStorage.write$.subscribe(async () => {
                this.writeSubscription = undefined;

                LazyStorage.storage.setMap(this.key, await this.map());
            });
        }
    }
}
