<div class="chart" [ngClass]="{hidden: !chartData.length || !chartOptions || isLoading}" [id]="id"></div>

<p class="widget-no-data no-data" *ngIf="(!chartData.length || !chartOptions) && !isLoading">{{'chart.no-data' | translate}}</p>

<p class="title" 
    [style.left.px]="left"
    [style.width.px]="width"
    [style.top.px]="top"
    [style.height.px]="height"
    *ngIf="title && chartData.length && chartOptions && !isLoading"
    [innerHTML]="title"
>
    {{title}}
</p>
