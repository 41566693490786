import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';

import { MapGeofence } from '../models/weavix-map.model';
import { PermissionAction } from '../permissions/permissions.model';
import { Utils } from '../utils/utils';
import { GeofenceService } from './geofence.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class GeofenceStateService extends StateServiceBase {
    geofences: Map<string, MapGeofence> = new Map();
    geofences$: Subject<StateUpdate<MapGeofence>> = new Subject();
    geofencesByFloorPlan: Map<string, Map<string, MapGeofence>> = new Map(); // Map<FloorPlanId, Geofence>

    constructor(
        private geofenceService: GeofenceService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)) return;
        const allGeos: MapGeofence[] = await this.geofenceService.getAll(c, facilityId, tags);
        this.geofences = Utils.toMap(allGeos);
        allGeos.forEach(g => this.updateGeofenceToFloorPlanMap(g));
    }

    async stop() {
        super.stop();
        this.geofences.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)) return;

        return (await this.geofenceService.subscribeGeofenceUpdates(c)).subscribe(async result => {
            const geofenceMap = result.payload;
            Object.keys(geofenceMap).forEach(k => {
                if (geofenceMap[k]) this.updateGeofenceToFloorPlanMap(geofenceMap[k]);
                else this.removeGeofenceFromFloorPlanMap(k);
            });
            this.updateFromMap(this.geofences, geofenceMap, this.geofences$);
        });
    }

    private removeGeofenceFromFloorPlanMap(id: string): void {
        const existingFloorPlanId: string = this.geofences.get(id)?.floorPlanId;
        if (existingFloorPlanId) this.geofencesByFloorPlan.get(existingFloorPlanId).delete(id);
    }

    private updateGeofenceToFloorPlanMap(geofence: MapGeofence, remove: boolean = false): void {
        if (!geofence?.floorPlanId) return;
        const existing = this.geofencesByFloorPlan.get(geofence.floorPlanId);
        if (existing) existing.set(geofence.id, geofence);
        else this.geofencesByFloorPlan.set(geofence.floorPlanId, new Map([[geofence.id, geofence]]));
    }
}

export class CustomGeofenceStateService extends StateServiceBase {
    geofences: Map<string, MapGeofence> = new Map();
    geofences$: Subject<StateUpdate<MapGeofence>> = new Subject();
    geofencesByFloorPlan: Map<string, Map<string, MapGeofence>> = new Map(); // Map<FloorPlanId, Geofence>

    constructor(
        private data: MapGeofence[],
        private updates$: Subject<MapGeofence[]>,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        this.geofences = Utils.toMap(cloneDeep(this.data));
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        if (!this.updates$) return;
        return this.updates$.subscribe(async geofences => {
            const geofenceMap = Utils.toObjectMap(geofences, (g) => g.id);
            this.updateFromMap(this.geofences, geofenceMap, this.geofences$);
        });
    }
}
