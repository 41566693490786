import { isArray, mergeWith } from  'lodash';

/**
 * Deeply merge two objects, but replace arrays instead of merge arrays.
 * This allows an updated object that has an empty array to replace the
 * same array in the original object.
 */
export function mergeCopyArrays<T1, T2, T3>(object: T1, source1: T2, source2?: T3): T1 & T2 & (T3 | undefined) {
    return mergeWith(object, source1, source2, mergeCustomizer);
}

/**
 * Lodash customizer to override arrays instead of merge
 */
function mergeCustomizer(objValue: any, srcValue: any) {
    if (isArray(objValue)) {
        return srcValue;
    }
}
