import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { MapFilterResult } from 'weavix-shared/models/weavix-map.model';
@Component({
    selector: 'app-filter-row-display',
    templateUrl: './filter-row-display.component.html',
    styleUrls: ['./filter-row-display.component.scss'],
})
export class FilterRowDisplayComponent {
    @Input() index: number = 0;
    @Input() result: MapFilterResult;
    @Output() clickEmit: EventEmitter<MapFilterResult> = new EventEmitter();
    @Output() selectEmit: EventEmitter<MapFilterResult> = new EventEmitter();

    lightTheme: boolean;

    constructor() {
        this.lightTheme = ThemeService.getLightTheme();
    }

    handleResClick(result: MapFilterResult): void {
        if (result?.disabled && result?.disabled()) return;
        else if (result.customClickAction) result.customClickAction();
        else this.clickEmit.emit(result);
    }

    handleSelectClick(result: MapFilterResult): void {
        if (result?.disabled && result?.disabled()) return;
        else if (result.customClickAction) result.customClickAction();
        else this.selectEmit.emit(result);
    }
}
