import { Subject } from 'rxjs';
import { MapWifiRouter } from '../models/weavix-map.model';
import { PermissionAction } from '../permissions/permissions.model';
import { Utils } from '../utils/utils';
import { NetworkLocationService } from './network-location.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class WifiRouterStateService extends StateServiceBase {
    wifiRouters: Map<string, MapWifiRouter> = new Map();
    wifiRouters$: Subject<StateUpdate<MapWifiRouter>> = new Subject();

    constructor(
        private networkLocationService: NetworkLocationService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;
        this.wifiRouters = Utils.toMap(await this.networkLocationService.getFacilityWifiRouters(c, facilityId, false));
    }

    async stop() {
        super.stop();
        this.wifiRouters.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;
        return null;
    }
}
