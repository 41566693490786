
<ng-container
    *ngIf="result"
    class="dashboard"
    [ngSwitch]="result.displayType"
>
    <ng-container *ngSwitchCase="'person'">
        <ng-container *ngTemplateOutlet="resultPerson; context: {res: result, resIndex: index}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'item'">
        <ng-container *ngTemplateOutlet="resultItem; context: {res: result, resIndex: index}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="resultContent; context: {res: result, resIndex: index}"></ng-container>
    </ng-container>
</ng-container>

<ng-template #resultPerson let-res="res" let-resIndex="resIndex">
    <div *ngIf="!res.hidden && res.data"
        class="res-item-person"
        [ngClass]="{'selected': res.selected || res.childrenSelected}"  
        (click)="handleSelectClick(res)"
    >
        <app-person-row-display [person]="res.data"></app-person-row-display>
    </div>
</ng-template>

<ng-template #resultItem let-res="res" let-resIndex="resIndex">
    <div *ngIf="!res.hidden && res.data"
        class="res-item-item"
        [ngClass]="{'selected': res.selected || res.childrenSelected}"  
        (click)="handleResClick(res)"
    >
        <app-item-row-display [item]="res.data"></app-item-row-display>
    </div>
</ng-template>

<ng-template #resultContent let-res="res" let-resIndex="resIndex">
    <div *ngIf="!res.hidden"
        id="{{'res-item-' + res.name}}"
        class="res-item"
        [ngClass]="{'selected': res.selected || res.childrenSelected, 'disabled': res?.disabled && res?.disabled.call(res)}"  
        (click)="handleSelectClick(res)"
    >
        <app-icon class="res-item-icon"
            [ngClass]="{'light': lightTheme}"
            *ngIf="(res?.children | keyvalue)?.length && !res.clickAction && !res.depth"
            [faIcon]="res.selected ? 'fa fa-minus' : 'fa fa-plus'"
        ></app-icon>

        <app-icon class="res-item-icon"
            [ngClass]="{'light': lightTheme}"
            *ngIf="res.icon"
            [icon]="res.icon"
        ></app-icon>

        <div class="res-item-text" [ngClass]="{'light': lightTheme}">{{res.name}}</div>

        <div class="res-item-appendix" [ngClass]="{'light': lightTheme}" *ngIf="res?.appendixText || res?.clickAction || res.appendixIcon" (click)="handleResClick(res)" >
            <div *ngIf="res?.appendixText; else noText" class="res-item-text" [ngClass]="{'light': lightTheme}">
                <app-icon *ngIf="res?.appendixIcon" [icon]="res.appendixIcon"></app-icon>
                &nbsp;
                {{res.appendixText.call(res)}}
            </div>
            <ng-template #noText>
                <div class="res-item-text" [ngClass]="{'light': lightTheme}">
                    <app-icon *ngIf="res?.appendixIcon" [icon]="res.appendixIcon"></app-icon>
                </div>
            </ng-template>

            <app-icon *ngIf="res.clickAction"
                class="res-res-icon mL10"
                [ngClass]="{'light': lightTheme}"
                [faIcon]="'fas fa-chevron-right'"
            ></app-icon>
        </div>
    </div>
</ng-template>
