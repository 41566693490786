/**
 * A "modern" sleep statement.
 *
 * @param ms The number of milliseconds to wait.
 */
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * A "modern" sleep statement with an exponential scaling component, useful for retry operations.
 *
 * @param baseMs The base number of milliseconds to wait.
 * @param exponent The base 2 exponent corresponding to the number of retries. Should be 1 or higher.
 */
export const exponentialSleep = (baseMs: number, exponent: number) => {
    const ms = baseMs * Math.pow(2, exponent);
    return sleep(ms);
};
 