import { Injectable, Injector } from '@angular/core';
import {
    Call,
    CallAgent,
    CallAgentKind,
    CallClient,
    CallEndReason,
    ConnectionState,
    ConnectionStateChangedReason,
    DeviceManager,
    IncomingCall,
    LocalVideoStream,
    RemoteParticipant,
    TeamsCall,
    TeamsCallAgent,
    TeamsIncomingCall,
    VideoOptions,
} from '@azure/communication-calling';
import { AzureCommunicationTokenCredential, CommunicationIdentifierKind, createIdentifierFromRawId, CommunicationUserKind, getIdentifierRawId } from '@azure/communication-common';
import { CommunicationAccessToken } from '@azure/communication-identity';
import { authentication, call as msCall } from '@microsoft/teams-js';
import { LocalDeviceUtility } from '@weavix/domain/src/utils/local-device-utility';
import { StAction, StObject } from '@weavix/models/src/analytics/analytics';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';
import { sleep } from 'weavix-shared/utils/sleep';
import { AlertService } from './alert.service';
import { AcsHttpService } from './vidvox/acs-http.service';
import * as moment from 'moment';
import { LoggedInUser, User } from 'weavix-shared/models/user.model';
import { AnalyticsService } from './analytics.service';
import { myUser, usersContext, User as MobxUser } from 'models-mobx/users-store/users-store';
import { ProfileService } from './profile.service';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { Channel } from 'models-mobx/channels-store/channel';
import { channelsContext } from 'models-mobx/channels-store/channels-store';
import { ChannelService } from './channel.service';
import { map, tap } from 'rxjs/operators';
import { RingbackCall, VidVoxRingbackService } from './vidvox/vidvox-ringback.service';
import { CallEndReasonInterpreter } from 'weavix-shared/utils/call-end-reason-interpreter';
import { myUserId } from 'models-mobx/my-profile-store/my-profile-store';

const DEFAULT_WAIT_MS = 10000;

export interface CallRequest {
    channel: Channel;
    initiator: User;
    type: CallType;
}

export enum CallType {
    Video = 'video',
    Voice = 'voice',
}

export interface CameraState {
    isDisabled: boolean;
}

export interface MuteState {
    isMuted: boolean;
}

export interface VideoCallingAccessToken extends CommunicationAccessToken {
    type: 'acs' | 'teams';
}

export enum CallSystemMessageType {
    AnsweredCall = 'answered-call',
    CalledChannel = 'called-channel',
    DeclinedCall = 'declined-call',
    MissedCall = 'missed-call',
}

export interface CallingSystemMessageRequest {
    type: CallSystemMessageType;
    userId?: string;
}

export interface VidVoxIncomingCall {
    id: string;
    callerUserId: string | null | undefined;

    callEnded$: Observable<VidVoxCallEnded>;

    accept(type: CallType): Promise<void>;
    reject(): Promise<void>;
}

export interface VidVoxCallEnded {
    isRejected: boolean;
    isUnansweredTimeout: boolean;
    isCancelled: boolean;
    isUnavailable: boolean;
    isError: boolean;
    errorKey: string | null | undefined;
}

export interface LobbyParticipantChange {
    added: RemoteParticipant[];
    removed: RemoteParticipant[];
}

export interface ConnectedStateChangedEvent {
    newValue: ConnectionState;
    oldValue: ConnectionState;
    reason?: ConnectionStateChangedReason;
}

@Injectable({
    providedIn: 'root',
})
export class AcsService {
    constructor(
        private readonly acsHttpService: AcsHttpService,
        private alertService: AlertService,
        private profileService: ProfileService,
        private injector: Injector,
        private readonly workaround: VidVoxRingbackService,
    ) { }
    
    private loggedInUser: LoggedInUser;
    private accessToken: VideoCallingAccessToken = null;
    private allowVideoCalls: boolean = false;
    private allowVoiceCalls: boolean = false;
    private callAgent: TeamsCallAgent | CallAgent = null;
    private callClient: CallClient = null;
    private currentCall: TeamsCall | Call = null;
    private deviceManager: DeviceManager = null;
    private localVideoStream: LocalVideoStream = null;
    private userAllowsVideo: boolean = false;
    private calledChannelId: string;

    private callType: CallType;
    private localMuteState: MuteState;
    private localCameraState: CameraState;
    private callConnectedTimestamp: number;
    private lastCallDuration: number;
    private tokenType: 'acs' | 'teams';
    private tokenWithRefresher: AzureCommunicationTokenCredential;

    // TODO: fix eslint rxjs/no-exposed-subjects
    callConnected$ = new Subject<Call | TeamsCall>();
    callEnded$ = new Subject<VidVoxCallEnded>();
    callIncoming$ = new Subject<VidVoxIncomingCall>();
    callRequested$ = new Subject<CallRequest>();
    callRinging$ = new Subject<void>();
    callStarted$ = new Subject<Call | TeamsCall | RingbackCall>();
    muteStateChanged$ = new Subject<MuteState>();
    remoteParticipantChanged$ = new Subject<RemoteParticipant[]>();
    lobbyParticipantChanged$ = new Subject<LobbyParticipantChange>();
    recording$ = new BehaviorSubject(false);
    transcribing$ = new BehaviorSubject(false);

    get currentCalledChannelId() {
        return this.calledChannelId;
    }

    get callAgentKind(): CallAgentKind {
        return this.callAgent?.kind;
    }

    setCurrentCalledChannelId(value: string) {
        this.calledChannelId = value;
    }

    async getCurrentAccessToken(): Promise<VideoCallingAccessToken> {
        if (!this.accessToken || this.accessToken.expiresOn <= new Date()) {
            this.accessToken = await this.acsHttpService.getAccessToken(null);
        }
        return this.accessToken;
    }

    async init(user: LoggedInUser, { allowVideoCalls = false, allowVoiceCalls = false } = {}): Promise<void> {
        this.loggedInUser = user;
        this.allowVideoCalls = allowVideoCalls;
        this.allowVoiceCalls = allowVoiceCalls;
        let acsToken: VideoCallingAccessToken = null;
        if (environment.teamsApp) {
            if (!await this.acsHttpService.hasAdToken(null)) {
                const testTokenRequest = {
                    successCallback: () => { },
                    failureCallback: (e: unknown) => {console.error('Failed to get auth token', e); },
                    url: `${window.location.origin}/teams/teams-authenticate?userId=${user.id}`,
                };
                await authentication.authenticate(testTokenRequest);
            }
            return;
        }

        // don't start ACS service if none of the user's accounts have video or voice permissions
        if (!this.profileService.hasAccountPermissionInAnyAccount(PermissionAction.VideoCalling)
            && !this.profileService.hasAccountPermissionInAnyAccount(PermissionAction.VoiceCalling)) return;

        while (!acsToken) {
            try {
                acsToken = await this.getCurrentAccessToken();
            } catch (e) {
                console.error(`Unable to get an access token: ${e}`);
                if (e.error?.message?.includes('Another object with the same value')) return;
            } finally {
                if (!acsToken) {
                    await sleep(DEFAULT_WAIT_MS);
                }
            }
        }

        if (!this.callClient) {
            const diagnostics = {
                appName: environment.analyticsSource,
                appVersion: environment.version,
                tags: [ environment.commit ],
            };
            this.callClient = new CallClient({ diagnostics });
            const refreshToken = async(): Promise<string> => (await this.acsHttpService.getAccessToken(null)).token;
            this.tokenWithRefresher = new AzureCommunicationTokenCredential({ tokenRefresher: refreshToken, refreshProactively: true });
            this.tokenType = this.accessToken.type;
            if (this.accessToken.type === 'teams') {
                this.callAgent = await this.callClient.createTeamsCallAgent(this.tokenWithRefresher);
                this.callAgent.on('incomingCall', async args => this.incomingCall(args.incomingCall));
                this.callAgent.on('connectionStateChanged', async value => await this.processAgentStateChange(value));
            } else {
                this.callAgent = await this.callClient.createCallAgent(this.tokenWithRefresher, { displayName: myUser().fullName });
                this.callAgent.on('incomingCall', async args => this.incomingCall(args.incomingCall));
            }
            this.workaround.startListening({
                onJoinMeeting: async (meetingLink, channelId, callType) => {
                    await this.joinTeamsMeeting(meetingLink, channelId, callType);
                },
                onOutgoingStarted: ({ channelId }) => {
                    this.callRinging$.next();
                    this.callStarted$.next({
                        kind: 'workaround',
                    });
                    this.setCurrentCalledChannelId(channelId);
                },
                onNotAccepted: args => this.callEnded$.next(args),
                onIncomingCall: args => this.callIncoming$.next(args),
            });
            this.deviceManager = await this.callClient.getDeviceManager();

            const defaultMicrophone = await LocalDeviceUtility.getDefaultAudioInputDevice();
            this.changeMicrophone(defaultMicrophone.deviceId);

            const defaultSpeaker = await LocalDeviceUtility.getDefaultAudioOutputDevice();
            this.changeSpeaker(defaultSpeaker.deviceId);
        }
    }

    // failsafe for when refresh token fails
    async processAgentStateChange(state: ConnectedStateChangedEvent) {
        if (state.reason === 'tokenExpired') {
            await this.restartAgent();
        }
    }

    async restartAgent() {
        console.warn('Restarting Call Agent...');
        try {
            await this.disposeAgent();
            await this.init(this.loggedInUser, { allowVideoCalls: this.allowVideoCalls, allowVoiceCalls: this.allowVoiceCalls });
        } catch (e) {
            console.error('Failed to restart Call Agent', e);
        }
    }

    async disposeAgent() {
        this.workaround.stopListening();
        if (this.isOnACall()) await this.endCall(true);
        await this.callAgent?.dispose();
        this.tokenWithRefresher?.dispose();
        this.callAgent = null;
        this.callClient = null;
        this.accessToken = null;
        this.deviceManager = null;
    }

    ready(): boolean {
        return this.accessToken && this.callAgent ? true : false;
    }

    isOnACall(): boolean { return !!this.currentCall; }

    async requestCall(channel: Channel, type: CallType, initiator: User) {
        this.callRequested$.next({ channel, type, initiator });
    }

    // Starts a video/voice call with one user. This call utilizes Teams library to start the call instead of ACS.
    // NOTE: weTeams allows teams -> acs calls!
    async startWeTeamsOneToOneCall(userId: string, callType: CallType) {
        try {
            if (!environment.teamsApp) await this.askPermissions(this.allowVideoCalls);
            const response = await this.acsHttpService.getUserIdentity(null, userId);
            if (!response.length) this.alertService.sendError(null, 'ERRORS.CALLS.USER_UNAVAILABLE');
            else {
                this.callType = callType;
                const modalities = callType === CallType.Video ? [msCall.CallModalities.Video] : [msCall.CallModalities.Audio];
                const targets = [response];

                AnalyticsService.track(
                    this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
                    StAction.Started,
                    this.constructor.name,
                    {
                        object: {
                            channelMemberCount: targets?.length + 1,
                        },
                    },
                );

                // @ts-expect-error - TODO: see todo in acs-http-service. why is this string[][] ?
                msCall.startCall({ targets, requestedModalities: modalities });
                const defaultChannelsContext = channelsContext.getDefault();
                const existingChannel = defaultChannelsContext.getChannelByPersonIds([myUser().id, userId]);
                if (existingChannel) {
                    await this.acsHttpService.createCallSystemMessage(null, existingChannel.id, { type: CallSystemMessageType.CalledChannel });
                } else {
                    const channelService = this.injector.get(ChannelService);
                    const newChannel = await defaultChannelsContext.createTemporary([myUser().id, userId]);
                    const createdChannel = await defaultChannelsContext.ensureCreated(newChannel);
                    await this.acsHttpService.createCallSystemMessage(null, createdChannel.id, { type: CallSystemMessageType.CalledChannel });
                    channelService.selectChannel.next({ id: createdChannel.id });
                }
            }
        } catch (e) {
            if (e) console.error('Failed to start (and join) a call.', e);
            else {
                this.alertService.sendError(null, 'ERRORS.CALLS.USER_UNAVAILABLE');
            }
        }
    }

    /** Starts a call between this user and recipient via ACS. */
    async startDirectCall(channelId: string, type: CallType): Promise<TeamsCall | Call> {
        await this.askPermissions(this.allowVideoCalls);
        this.callType = type;

        const channel = channelsContext.getDefault().getChannelById(channelId);
        await channelsContext.getDefault().ensureCreated(channel);
        const recipientIds = await this.acsHttpService.getChannelRecipientsIds(null, channelId);
        if (!recipientIds?.length) {
            throw new Error(`Cannot start a call because channel ${channelId} has no recipients.`);
        }

        if (type === CallType.Video && this.userAllowsVideo && !this.localVideoStream) {
            this.localVideoStream = await this.createLocalVideoStream();
        }
        const videoOptions = this.localVideoStream && type === CallType.Video ? { localVideoStreams: [this.localVideoStream] } : undefined;

        const participants = recipientIds.map(rawId => createIdentifierFromRawId(rawId));
        if (this.workaround.isNeeded(this.callAgent.kind, participants)) {
            await this.workaround.requestChannelRingback(channel, { joinWith: type });

            // Normally this is stopped by the call UI, but we don't always land in a call.
            this.alertService.setAppLoading(false);

            // Exit early because we're not actually starting a call.
            return;
        } else if (this.tokenType === 'teams') {
            // Group calls required a `threadId`, so we only support 1-1 Teams calls for now.
            if (participants.length > 1) throw new Error('Cannot start a Teams group call without a threadId.');
            const singleParticipant = participants[0] as Exclude<CommunicationIdentifierKind, CommunicationUserKind>;
            this.currentCall = (this.callAgent as TeamsCallAgent).startCall(singleParticipant, { videoOptions });
        } else {
            // If `participants` includes any Teams users, this will be a Teams interop call.
            // This will fail if the Teams tenant does not have ACS interop enabled.
            this.currentCall = (this.callAgent as CallAgent).startCall(participants, { videoOptions });
        }
        this.setCurrentCalledChannelId(channelId);
        this.subscribeToEvents(this.currentCall);
        this.callStarted$.next(this.currentCall);

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Started,
            this.constructor.name,
            {
                object: {
                    channelId,
                    callId: this.currentCall.id,
                    channelMemberCount: recipientIds.length + 1,
                },
            },
        );
        return this.currentCall;
    }

    async joinTeamsMeeting(meetingLink: string, channelId: string | null, type: CallType) {
        await this.askPermissions(this.allowVideoCalls);
        this.callType = type;

        if (type === CallType.Video && this.userAllowsVideo && !this.localVideoStream) {
            this.localVideoStream = await this.createLocalVideoStream();
        }
        const videoOptions = this.localVideoStream && type === CallType.Video ? { localVideoStreams: [this.localVideoStream] } : undefined;

        this.currentCall = this.callAgent.join({ meetingLink }, { videoOptions });
        this.setCurrentCalledChannelId(channelId);
        this.subscribeToEvents(this.currentCall);
        this.callStarted$.next(this.currentCall);

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Joined,
            this.constructor.name,
            {
                object: {
                    callId: this.currentCall.id,
                },
            },
        );
        return this.currentCall;
    }

    async endCall(forEveryone: boolean = false): Promise<void> {
        if (this.currentCall?.state === 'Ringing') {
            const callee = channelsContext.getDefault().getChannelById(this.currentCalledChannelId)?.otherUsers[0]?.id;
            if (callee) this.createMissedCallNotification(null, this.currentCall.id, myUserId(), this.calledChannelId, callee);
        }
        if (this.currentCall) {
            this.currentCall.hangUp( { forEveryone: forEveryone });
        } else {
            if (this.workaround.isActive && this.currentCalledChannelId) {
                this.workaround.cancelChannelRingback();
            }
            this.callEnded$.next({
                isRejected: false,
                isUnansweredTimeout: false,
                isCancelled: false,
                isUnavailable: true,
                isError: false,
                errorKey: null,
            });
        }

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Ended,
            this.constructor.name,
            {
                object: {
                    callId: this.currentCall?.id,
                    callDuration: this.lastCallDuration,
                    video: this.localCameraState?.isDisabled === false ? 'on' : 'off',
                    audio: this.localMuteState?.isMuted === false ? 'on' : 'off',
                },
            },
        );
    }

    private async createLocalVideoStream(): Promise<LocalVideoStream> {
        const selectedCamera = await LocalDeviceUtility.getDefaultVideoDevice();
        const cameras = await this.deviceManager.getCameras();
        let camera = cameras.find(x => x.id.replace('camera:', '') === selectedCamera.deviceId);
        if (!camera) {
            camera = cameras[0];
            LocalDeviceUtility.setDefaultVideoInput(camera.id.replace('camera:', ''));
        }
        if (camera) {
            return new LocalVideoStream(camera);
        }
        throw new Error('camera not available');
    }

    private async processStateChange(): Promise<void> {
        console.log(`Current Call ${this.currentCall.id} state changed: ${this.currentCall.state}`);
        switch (this.currentCall.state) {
            case 'Connected':
                this.callConnectedTimestamp = moment.now();
                this.callConnected$.next(this.currentCall);
                break;
            case 'Disconnected': {
                console.log(`Current call ended with code: ${this.currentCall.callEndReason.code} and sub-code: ${this.currentCall.callEndReason.subCode}`);
                this.lastCallDuration = moment.now() - this.callConnectedTimestamp;
                const isUnavailable = CallEndReasonInterpreter.isUnavailable(this.currentCall.callEndReason);
                const isUnansweredTimeout = CallEndReasonInterpreter.isUnansweredTimeout(this.currentCall.callEndReason);
                if (isUnansweredTimeout || isUnavailable) {
                    const callee = channelsContext.getDefault().getChannelById(this.currentCalledChannelId)?.otherUsers[0]?.id;
                    if (callee) this.createMissedCallNotification(null, this.currentCall.id, myUserId(), this.calledChannelId, callee);
                }
                this.callEnded$.next({
                    isRejected: CallEndReasonInterpreter.isRejected(this.currentCall.callEndReason),
                    isUnansweredTimeout,
                    isCancelled: CallEndReasonInterpreter.isCancelledCall(this.currentCall.callEndReason),
                    isUnavailable,
                    isError: CallEndReasonInterpreter.isError(this.currentCall.callEndReason),
                    errorKey: CallEndReasonInterpreter.getTerminationErrorKey(this.currentCall.callEndReason),
                });
                this.cleanUpCall();
                break;
            }
            case 'Ringing':
                this.callRinging$.next();
        }
    }

    private cleanUpCall(): void {
        this.unsubscribeToEvents(this.currentCall);
        this.currentCall = null;
        this.callConnectedTimestamp = 0;
        this.localCameraState = null;
        this.localMuteState = null;
        this.callType = null;
        this.localVideoStream = null;
    }

    private async incomingCall(call: TeamsIncomingCall | IncomingCall): Promise<void> {
        console.log(`Incoming call: id ${call.id} from "${call.callerInfo.displayName}`);
        if (this.currentCall) {
            await call.reject();
            return;
        }
        const user = this.getUserByCommunicationIdentifierKind(call.callerInfo.identifier);
        this.calledChannelId = channelsContext.getDefault().getChannelByPersonIds([myUser().id, user?.id])?.id;
        const callEnded$ = fromEvent<CallEndReason>(call, 'callEnded').pipe(
            tap(reason => console.log(`Incoming call ended. code: ${reason.code} and sub-code: ${reason.subCode}`)),
            map(reason => ({
                id: call.id,
                isRejected: CallEndReasonInterpreter.isRejected(reason),
                isUnansweredTimeout: CallEndReasonInterpreter.isUnansweredTimeout(reason),
                isCancelled: CallEndReasonInterpreter.isCancelledCall(reason),
                isUnavailable: CallEndReasonInterpreter.isUnavailable(reason),
                isError: CallEndReasonInterpreter.isError(reason),
                errorKey: CallEndReasonInterpreter.getTerminationErrorKey(reason),
            } as VidVoxCallEnded)),
        );
        this.callIncoming$.next({
            id: call.id,
            callerUserId: user?.id,
            callEnded$,
            accept: async (type: CallType) => await this.acceptCall(call, type),
            reject: async () => await this.rejectCall(call),
        });
        callEnded$.subscribe(endedArgs => {
            const channel = channelsContext.getDefault().getChannelByPersonIds([ myUserId(), user?.id ]);
            if (endedArgs.isUnansweredTimeout || endedArgs.isCancelled) {
                this.createMissedCallNotification(this, call.id, user?.id, channel?.id);
            }
        });
    }

    private async acceptCall(call: TeamsIncomingCall | IncomingCall, type: CallType): Promise<void> {
        if (!this.allowVideoCalls && !this.allowVoiceCalls) {
            console.error('User not allowed to receive either type of calls. Rejecting this call.');
            return await this.rejectCall(call);
        }

        await this.askPermissions(this.allowVideoCalls);
        this.callType = type;
        const videoOptions: VideoOptions = {};
        if (this.allowVideoCalls && this.userAllowsVideo && type === CallType.Video) {
            if (!this.localVideoStream) {
                this.localVideoStream = await this.createLocalVideoStream();
            }
            videoOptions.localVideoStreams = [this.localVideoStream];
        }
        this.currentCall = await call.accept({ videoOptions: videoOptions });
        this.subscribeToEvents(this.currentCall);
        this.callStarted$.next(this.currentCall);

        if (this.currentCalledChannelId) await this.acsHttpService.createCallSystemMessage(null, this.currentCalledChannelId, { type: CallSystemMessageType.AnsweredCall });

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Joined,
            this.constructor.name,
            {
                object: {
                    callId: this.currentCall?.id,
                },
            },
        );
    }

    private async rejectCall(call: TeamsIncomingCall | IncomingCall): Promise<void> {
        await call.reject();
        this.callEnded$.next({
            isRejected: true,
            isUnansweredTimeout: false,
            isCancelled: false,
            isUnavailable: false,
            isError: false,
            errorKey: null,
        });
        if (this.currentCalledChannelId) await this.acsHttpService.createCallSystemMessage(null, this.currentCalledChannelId, { type: CallSystemMessageType.DeclinedCall });
    }

    async muteMicrophone(): Promise<boolean> {
        if (!this.currentCall || this.currentCall.isMuted) return false;
        await this.currentCall.mute();
        if (this.currentCall.isMuted) this.muteStateChanged$.next({ isMuted: true });
        AnalyticsService.track(StObject.CallAudio, StAction.Toggled, this.constructor.name, { action: 'off' });
        return this.currentCall.isMuted;
    }

    async unmuteMicrophone(): Promise<boolean> {
        if (!this.currentCall || !this.currentCall.isMuted) return false;
        await this.currentCall.unmute();
        if (!this.currentCall.isMuted) this.muteStateChanged$.next({ isMuted: false });
        AnalyticsService.track(StObject.CallAudio, StAction.Toggled, this.constructor.name, { action: 'on' });
        return !this.currentCall.isMuted;
    }

    async turnOffCamera(): Promise<boolean> {
        if (!this.currentCall && !this.localVideoStream) return false;
        await this.currentCall.stopVideo(this.localVideoStream);
        this.localVideoStream = null;
        AnalyticsService.track(StObject.CallCamera, StAction.Toggled, this.constructor.name, { action: 'off' });
        return true;
    }

    async turnOnCamera(): Promise<boolean> {
        if (!this.currentCall && this.localVideoStream) return false;
        this.localVideoStream = await this.createLocalVideoStream();
        await this.currentCall.startVideo(this.localVideoStream);
        AnalyticsService.track(StObject.CallCamera, StAction.Toggled, this.constructor.name, { action: 'on' });
        return true;
    }

    async changeCamera(deviceId: string): Promise<boolean> {
        if (!this.currentCall || !this.localVideoStream) return false;
        const cameras = await this.deviceManager.getCameras();
        const camera = cameras.find(x => x.id.replace('camera:', '') === deviceId);
        if (camera) this.localVideoStream.switchSource(camera);
        return true;
    }

    async changeMicrophone(deviceId: string): Promise<boolean> {
        if (!this.deviceManager) return false;
        const microphones = await this.deviceManager.getMicrophones();
        const microphone = microphones.find(x => x.id.replace('microphone:', '') === deviceId);
        if (microphone) this.deviceManager.selectMicrophone(microphone);
        return true;
    }

    async changeSpeaker(deviceId: string): Promise<boolean> {
        if (!this.deviceManager) return false;
        const speakers = await this.deviceManager.getSpeakers();
        const speaker = speakers.find(x => x.id.replace('speaker:', '') === deviceId);
        if (speaker) this.deviceManager.selectSpeaker(speaker);
        return true;
    }

    async askPermissions(wantVideo: boolean): Promise<void> {
        if (!this.deviceManager) this.deviceManager = await this.callClient.getDeviceManager();
        this.userAllowsVideo = false;
        if (wantVideo) {
            const callResponse = await this.deviceManager.askDevicePermission({ audio: false, video: true });
            this.userAllowsVideo = callResponse.video;
        }
        await this.deviceManager.askDevicePermission({ audio: true, video: false });
    }

    async processMuteStateChange(): Promise<void> {
        console.log(`Call ${this.currentCall.id} is now ${this.currentCall.isMuted ? '' : 'un'}muted.`);
        this.muteStateChanged$.next({ isMuted: this.currentCall.isMuted });
    }

    async processRemoteParticipantsChange(): Promise<void> {
        this.remoteParticipantChanged$.next(this.currentCall.remoteParticipants as RemoteParticipant[]);
    }

    async processLobbyParticipantsChange(remoteParticipant: LobbyParticipantChange): Promise<void> {
        this.lobbyParticipantChanged$.next(remoteParticipant);
    }

    private subscribeToEvents(call: TeamsCall | Call): void {
        // TODO: these can all be converted from jQuery-style events to Angular-friendly RxJS with fromEvent.
        call.on('isMutedChanged', () => this.processMuteStateChange());
        call.on('stateChanged', () => this.processStateChange());
        call.on('remoteParticipantsUpdated', () => this.processRemoteParticipantsChange());
        const lobby = call.lobby;
        if (lobby) lobby.on('lobbyParticipantsUpdated', change => this.processLobbyParticipantsChange(change));
    }

    private unsubscribeToEvents(call: TeamsCall | Call): void {
        call.off('isMutedChanged', () => this.processMuteStateChange());
        call.off('stateChanged', () => this.processStateChange());
        call.off('remoteParticipantsUpdated', () => this.processRemoteParticipantsChange());
        const lobby = call.lobby;
        if (lobby) lobby.off('lobbyParticipantsUpdated', change => this.processLobbyParticipantsChange(change));
    }

    get hasLocalVideoStream(): boolean {
        return !!this.localVideoStream;
    }

    get isMuted(): boolean {
        return this.currentCall ? this.currentCall.isMuted : false;
    }

    getUserByCommunicationIdentifierKind(identifier: CommunicationIdentifierKind | null): MobxUser | null {
        if (!identifier) return null;
        else if (identifier.kind === 'microsoftTeamsUser') return usersContext.getDefault().getUserByTeamsId(identifier.microsoftTeamsUserId);
        else if (identifier.kind === 'communicationUser') return usersContext.getDefault().getUserByAcsId(identifier.communicationUserId);
        else return null;
    }

    logCall(call: TeamsCall | Call): void {
        console.log(`${call.direction} ${call.kind} with ID ${call.id} details:`);
        console.log(`This call is currently ${call.isMuted ? 'muted' : 'unmuted'} and in ${call.state} state.`);
        // eslint-disable-next-line max-len
        console.log(`Local participant has ${call.localAudioStreams.length} audio streams (${call.localAudioStreams.map(las => las.mediaStreamType).join()}) and ${call.localVideoStreams.length} video streams (${call.localVideoStreams.map(lvs => lvs.mediaStreamType).join()}).`);
        console.log(`Number of remote participants: ${call.remoteParticipants.length}`);
        call.remoteParticipants.forEach(rp => {
            const id = getIdentifierRawId(rp.identifier);
            console.log(`Remote participant ${id} (${rp.displayName}) has ${rp.videoStreams.length} video streams (${rp.videoStreams.map(x => x.mediaStreamType).join()}), and is ${rp.isMuted ? '' : 'not '}muted.`);
            const vs = rp.videoStreams.find(s => s.mediaStreamType === 'Video');
            if (vs) {
                console.log(`Remote participant ${id} 'Video' stream has a size of ${vs.size.width} by ${vs.size.height} and is ${vs.isAvailable ? '' : 'not '}available.`);
            } else {
                console.log(`Remote participant ${id} has no 'Video' streams.`);
            }
        });
    }

    async createMissedCallNotification(component: any, callId: string, callerId: string, channelId?: string, calleeId?: string) {
        try {
            if (channelId) await this.acsHttpService.createCallSystemMessage(component, channelId, { type: CallSystemMessageType.MissedCall, forOtherUser: !!calleeId });
            await this.acsHttpService.createMissedCallNotification(component, { callId, callerId, calleeId: calleeId ?? null });
        } catch (e) {
            console.error('failed to create missed call notification', e);
        }
    }
}
