
import { CallEndReason } from '@azure/communication-calling';

// CallEndReasonInterpreter is a utility class to help determine
// context from Azure Communication's CallEndReason results.
//
// This is a mirror of the call end reason interpreter available in
// our weavix mobile code.
export class CallEndReasonInterpreter {
    /**
     * Determines if the call was declined the call's recipient.
     */
    static isRejected(reason?: CallEndReason): boolean {
        return reason?.code === 603;
    }

    /**
     * Determines if the call ended due to unexpected error.
     */
    static isError(reason?: CallEndReason): boolean {
        return reason?.code >= 400 && reason?.code <= 599 && !this.isExpectedError(reason);
    }

    /**
     * Determines if the call ended due to an expected error.
     *
     * According to Azure Communication Service, calls cancelled, declined, ended due to endpoint
     * mismatch, or failed to generated media offered are reasons for "expected" errors.
     */
    static isExpectedError(reason?: CallEndReason): boolean {
        return reason?.code === 487;
    }

    /** True if the callee did not pick up and the ringing timed out. */
    static isUnansweredTimeout(reason?: CallEndReason) {
        return reason?.code === 487 && reason.subCode === 10004;
    }

    /** True if the caller cancelled before the ringing timed out. */
    static isCancelledCall(reason?: CallEndReason) {
        return reason?.code === 487 && reason.subCode === 0;
    }

    static isUnavailable(reason?: CallEndReason) {
        return (reason.code === 480 && [10037, 10076].includes(reason.subCode));
    }

    static isAcceptedElsewhere(reason?: CallEndReason) {
        return (reason?.code === 487 && reason.subCode === 10003);
    }

    static isRejectedElsewhere(reason?: CallEndReason) {
        return (reason?.code === 487 && reason.subCode === 10024);
    }

    /**
     * Gets translation keys for error codes, or null if no error occurred.
     */
    static getTerminationErrorKey(reason?: CallEndReason): string | null {
        if (!this.isError(reason)) return null;

        if (reason.code >= 500) return 'ERRORS.GENERIC.UNKNOWN';

        switch (reason.code) {
            case 403:
                switch (reason.subCode) {
                    case 10124:
                        return 'ERRORS.ACS.NOT-PRIVARTE-PREVIEW';
                }
                break;
            case 404:
                return 'ERRORS.ACS.NOT-FOUND';
            case 408:
                switch (reason.subCode) {
                    case 8537:
                        return 'ERRORS.ACS.TIMEOUT-INACTIVITY';
                    default:
                        return 'ERRORS.ACS.TIMEOUT';
                }
            case 410:
                switch (reason.subCode) {
                    case 3111:
                        return 'calling.call-form.unavailable';
                }
                break;
            case 480:
                switch (reason.subCode) {
                    case 10037:
                        return 'ERRORS.ACS.REMOTE-NOT-FOUND';
                    case 10076:
                        return 'ERRORS.ACS.REMOTE-COULD-NOT-REACH';
                    case 10134:
                        return 'ERRORS.ACS.REMOTE-FAILED-ROUTE';
                    case 10199:
                        return 'ERRORS.ACS.REMOTE-NO-PRIVATE-CALLING';
                }
                break;
            case 490:
            case 491:
            case 496:
            case 497:
            case 498:
                return 'ERRORS.ACS.NETWORK';
        }
        return 'ERRORS.GENERIC.UNKNOWN-ERROR-TRY-AGAIN';
    }
}
