<div class="entity-list-container" [ngStyle]="{'border-color': headerColor, 'max-height.px': maxHeight ? maxHeight : 'auto'}">
    <div *ngIf="title"
        [ngStyle]="{'background': headerColor}"
        class="header"
    >
        <p class="text">{{title.translate ? (title.value | translate) : title.value}}</p>

        <app-icon
            *ngIf="includeClose"
            class="cPointer"
            (click)="closeOutput.emit()"
            [faIcon]="'fa fa-times'"
            [fontSize]="'20px'"
        ></app-icon>
    </div>

    <div class="list">
        <div *ngFor="let item of listItems"
            [ngClass]="{'cPointer': itemsClickable}"
            (click)="itemsClickable && handleItemClick(item)"
            class="item">
            <ng-container [ngSwitch]="item.type">
                <ng-container *ngSwitchCase="FilterResultType.Person">
                    <ng-container *ngTemplateOutlet="personItem; context {person: item.entity, postFixValue: item.postFixValue}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="FilterResultType.Geofence">
                    <ng-container *ngTemplateOutlet="geofenceItem; context {geo: item.entity, postFixValue: item.postFixValue}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="FilterResultType.Craft">
                    <ng-container *ngTemplateOutlet="craftItem; context {craft: item.entity, postFixValue: item.postFixValue}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="FilterResultType.Copmany">
                    <ng-container *ngTemplateOutlet="companyItem; context {company: item.entity, postFixValue: item.postFixValue}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="FilterResultType.Walt">
                    <ng-container *ngTemplateOutlet="waltItem; context {walt: item.entity, postFixValue: item.postFixValue}"></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #personItem let-person="person" let-postFixValue="postFixValue">
    <div class="item-image-container">
        <app-avatar [avatarInput]="{
                height: 30,
                width: 30,
                img: person.avatarFile,
                placeholder: { 'icon': 'fas fa-user', 'iconColor': 'white' }
            }"
            [readonly]="true"
        ></app-avatar>
    </div>

    <ng-container *ngTemplateOutlet="nameAndPostFixValue; context {name: person.fullName, subName: person.company?.name, postFixValue: postFixValue}"></ng-container>
</ng-template>

<ng-template #geofenceItem let-geo="geo" let-postFixValue="postFixValue">
    <div class="item-image-container">
        <div class="color-dot" [ngStyle]="{'background': geo?.color}">
            <app-icon [icon]="featureIcons.geofences.icon"></app-icon>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="nameAndPostFixValue; context {name: geo.name, postFixValue: postFixValue}"></ng-container>
</ng-template>

<ng-template #craftItem let-craft="craft" let-postFixValue="postFixValue">
    <div class="item-image-container">
        <div class="color-dot" [ngStyle]="{'background': craft.color ?? 'transparent'}">
            <app-icon [faIcon]="'fas fa-tools'"></app-icon>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="nameAndPostFixValue; context {name: craft.name, postFixValue: postFixValue}"></ng-container>
</ng-template>

<ng-template #companyItem let-company="company" let-postFixValue="postFixValue">
    <div class="item-image-container">
        <div *ngIf="company.logo" class="company.logo">
            <img [src]="company.logo" />
        </div>
    
        <div *ngIf="!company.logo" class="color-dot" [ngStyle]="{'background': company.color}"></div>
    </div>

    <ng-container *ngTemplateOutlet="nameAndPostFixValue; context {name: company.name, postFixValue: postFixValue}"></ng-container>
</ng-template>

<ng-template #waltItem let-walt="walt" let-postFixValue="postFixValue">
    <div class="item-image-container">
        <div class="image-icon"><i class="icon-walt"></i></div>
    </div>

    <ng-container *ngTemplateOutlet="nameAndPostFixValue; context {name: walt.id, subName: walt.person?.fullName, postFixValue: postFixValue}"></ng-container>
</ng-template>

<ng-template #nameAndPostFixValue let-name="name" let-subName="subName" let-postFixValue="postFixValue">
    <div *ngIf="name" class="name-container">
        <p class="name">{{name}}</p> 
        <p *ngIf="subName" class="sub-name">{{subName}}</p>
    </div>

    <p class="post-fix-value" *ngIf="postFixValue">{{postFixValue}}</p>
</ng-template>