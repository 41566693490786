interface FeatureBackendResult {
    apiUrl: string,
    mqttUrl: string,
}

export function getFeatureBackend(version: string): FeatureBackendResult | null {
    // only on a feature branch pointed at dev
    if (version.match(/^[0-9]/) || version.match(/^(demo|stage|BUILD)/)) {
        return null;
    }

    const feature = version.match(/[a-z0-9-]+/i)?.[0];
    if (!feature || feature === 'local') return null;

    return {
        apiUrl: `https://api-${feature}.weavixdev.com`,
        mqttUrl: `mqtt-${feature}.weavixdev.com`,
    };
}
