import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FolderType } from 'weavix-shared/models/folder.model';
import { FormAction } from 'weavix-shared/models/forms.model';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';



@AutoUnsubscribe()
@Component({
  selector: 'app-parent-edit-form',
  templateUrl: './parent-edit-form.component.html',
  styleUrls: ['./parent-edit-form.component.scss'],
})
export class ParentEditFormComponent implements OnInit {
    @Input() parentId: string = '';
    @Input() folderType: FolderType;
    @Input() permission: PermissionAction;
    @Input() facilityId: string = '';
    @Output() formCancelOutput: EventEmitter<void> = new EventEmitter();
    @Output() formSubmitOutput: EventEmitter<string> = new EventEmitter();

    formAction = FormAction;
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.form = this.getForm();
    }

    handleCancelClick(): void {
        this.formCancelOutput.emit();
        this.form.reset();
    }

    async handleSubmit() {
        const parent = this.form.value.parentId[0];
        this.formSubmitOutput.emit(parent === '' ? null : parent);
    }

    getForm(): FormGroup {
        return this.fb.group({
            parentId: new FormControl([this.parentId || ''], [Validators.required]),
        });
    }
}
