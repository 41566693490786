import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Chip } from '../chip-list.component';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {

    @Input() chip: Chip | string;
    @Input() displayKey: string = 'name';
    @Input() selectable: boolean = false;
    @Input() disabled: boolean = false;
    @Input() removable: boolean = true;
    @Input() translate: boolean = true;
    @Output() removeOutput: EventEmitter<Chip | string> = new EventEmitter();

    constructor() { }

    get inputIsString(): boolean {
        return typeof this.chip === 'string';
    }
}
