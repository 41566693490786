import { PermissionApiResponse, PermissionAction, PermissionApiResponseItem } from '@weavix/models/src/permission/permissions.model';

export class PermissionChecker {

    constructor(private permissions: PermissionApiResponse) {}

    // returns true if there is:
    // * an account permission
    // * facility specified and a facility permission matching
    // * folder specified and a folder permission matching
    public hasPermission(action: PermissionAction, facilityId?: string, folderId?: string): boolean {
        return this.permissions.grants
            .filter(perm => this.isPermissionInScope(perm, facilityId, folderId))
            .some(perm => this.isPermissionGranted(perm, action));
    }

    // returns true if there is:
    // * an account permission
    // * a facility permission matching
    public hasPermissionInAnyFacility(action: PermissionAction): boolean {
        return this.permissions.grants
            .filter(perm => this.isPermissionInAnyFacilityScope(perm))
            .some(perm => this.isPermissionGranted(perm, action));
    }

    // returns true if there is:
    // * an account permission
    // * no facility specified and any facility permission
    // * facility specified and a matching facility permission
    // * no folder specified and any folder premission
    // * folder specified and a matching folder permission
    public hasAnyPermission(action: PermissionAction, facilityId?: string, folderId?: string): boolean {
        return this.permissions.grants
            .filter(perm => !facilityId || perm.scope.facilityId === facilityId)
            .filter(perm => !folderId || !perm.scope.folderIds?.length || perm.scope.folderIds?.includes(folderId))
            .some(perm => this.isPermissionGranted(perm, action));
    }

    // returns true if there is:
    // * an account permission
    // * facility specified and a matching facility permission
    // * no folder specified and any folder permission
    // * folder specified and a matching folder permission
    public hasFacilityOrAnyFolderPermission(action: PermissionAction, facilityId?: string, folderId?: string): boolean {
        return this.permissions.grants
            .filter(perm => !perm.scope.facilityId || perm.scope.facilityId === facilityId)
            .filter(perm => !folderId || !perm.scope.folderIds?.length || perm.scope.folderIds?.includes(folderId))
            .some(perm => this.isPermissionGranted(perm, action));
    }

    addFolder(id: string, parentId: string) {
        this.permissions.grants.forEach(x => {
            const parent = x.scope.folderIds?.indexOf(parentId);
            if (parent >= 0) {
                x.scope.folderIds.push(id);
            }
        });
    }

    private isPermissionInScope(permission: PermissionApiResponseItem, facilityId?: string, folderId?: string): boolean {
        if (permission.scope.facilityId && facilityId !== permission.scope.facilityId) {
            return false;
        }
        if (permission.scope.folderIds?.length && !permission.scope.folderIds.some(scopeFolderId => folderId === scopeFolderId)) {
            return false;
        }
        return true;
    }

    private isPermissionInAnyFacilityScope(permission: PermissionApiResponseItem): boolean {
        return !permission.scope.folderIds?.length;
    }

    private isPermissionGranted(permission: PermissionApiResponseItem, action: PermissionAction): boolean {
        return permission.grantedActions.some(grantedAction => this.isActionGranted(action, grantedAction));
    }

    private isActionGranted(desiredAction: PermissionAction, grantedAction: PermissionAction): boolean {
        if (!this.permissions.enabledActions.includes(desiredAction)) return false;
        return grantedAction === PermissionAction.AdministerAccount ||
            grantedAction === desiredAction;
    }

    hasAccountPermission(action: PermissionAction) {
        return this.permissions.enabledActions.includes(action);
    }
}
