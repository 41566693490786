
/* Directives */
import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';
import { DigitOnlyDirective } from 'weavix-shared/directives/digit-only.directive';
import { PanDirective } from 'weavix-shared/directives/pan.directive';
import { PermissionDirective } from 'weavix-shared/directives/permission.directive';
import { PreventInputDirective } from 'weavix-shared/directives/prevent-input.directive';
import { ResizeDirective } from 'weavix-shared/directives/resize.directive';
import { ZoomDirective } from 'weavix-shared/directives/zoom.directive';

/* Pipes */
import { CommaNumberPipe } from 'weavix-shared/pipes/comma-number.pipe';
import { LinkifyPipe } from 'weavix-shared/pipes/linkify.pipe';
import { MaybeDecimalPipe } from 'weavix-shared/pipes/maybe-decimal.pipe';
import { ObjectKeysPipe } from 'weavix-shared/pipes/object-keys.pipe';
import { SafeHtmlPipe } from 'weavix-shared/pipes/safe-html.pipe';
import { ShortNumberPipe } from 'weavix-shared/pipes/short-number.pipe';
import { TimeAgoPipe } from 'weavix-shared/pipes/time-ago.pipe';
import { HtmlEncode } from './pipes/html-encode.pipe';


@NgModule({
    declarations: [
        ClickOutsideDirective,
        CommaNumberPipe,
        DigitOnlyDirective,
        PreventInputDirective,
        LinkifyPipe,
        MaybeDecimalPipe,
        HtmlEncode,
        PermissionDirective,
        SafeHtmlPipe,
        ShortNumberPipe,
        ObjectKeysPipe,
        TimeAgoPipe,
        ZoomDirective,
        PanDirective,
        ResizeDirective,
    ],
    imports: [
    ],
    exports: [
        ClickOutsideDirective,
        CommaNumberPipe,
        LinkifyPipe,
        HtmlEncode,
        MaybeDecimalPipe,
        PermissionDirective,
        SafeHtmlPipe,
        ShortNumberPipe,
        TimeAgoPipe,
        ZoomDirective,
        PanDirective,
        ResizeDirective,
        ObjectKeysPipe,
    ],
})
export class WeavixSharedModule { }
