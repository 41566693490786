import { Subject } from 'rxjs';
import { Facility, removeBeaconNumberPrefix } from '../models/facility.model';
import { MapBeacon } from '../models/weavix-map.model';
import { PermissionAction } from '../permissions/permissions.model';
import { Utils } from '../utils/utils';
import { FacilityService } from './facility.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class BeaconStateService extends StateServiceBase {
    beacons: Map<string, MapBeacon> = new Map();
    beacons$: Subject<StateUpdate<MapBeacon>> = new Subject();

    constructor(
        private facilityService: FacilityService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;
        const facility = await this.facilityService.get(c, facilityId, false);
        this.beacons = Utils.toMap(facility.beacons.map(b => removeBeaconNumberPrefix(b)));
    }

    async stop() {
        super.stop();
        this.beacons.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;

        return (await this.facilityService.subscribeFacilityUpdates(c, accountId, facilityId)).subscribe(async result => {
            const facility = Object.values(result.payload)[0] as Facility;
            const beaconsMap = Utils.toObjectMap(facility.beacons.map(b => removeBeaconNumberPrefix(b)), b => b.id, b => b);
            const updatedIds = Object.keys(beaconsMap);
            const deletedBeacons = Array.from(this.beacons.values()).filter(b => !updatedIds.some(id => id === b.id));
            const updateMap = Object.assign({} , beaconsMap, Utils.toObjectMap(deletedBeacons, b => b.id, b => null));

            this.updateFromMap(this.beacons, updateMap, this.beacons$);
        });
    }
}
