import { idProp, model, Model, prop } from '@weavix/mobx';

@model('FacilityPerson')
export class FacilityPerson extends Model({
    id: idProp, // facilityId-personId
    accountId: prop<string>(),
    personId: prop<string>(),
    group: prop<string>(),
    facilityId: prop<string>(),
}) {
}
