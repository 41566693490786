import { PointOptionsObject } from 'highcharts';

export enum PeopleOverviewChart {
    CompaniesOnSiteChart = 'companies-on-site',
    CraftsOnSiteChart = 'crafts-on-site',
    TimeUtilizationChart = 'time-utilization',
    HrsWorkedByAreaChart = 'hours-worked-by-area',
}

export const PeopleOverviewChartTitleKey: {[key in PeopleOverviewChart]: string } = {
    [PeopleOverviewChart.CompaniesOnSiteChart]: 'people-overview.number-of-companies-on-site',
    [PeopleOverviewChart.CraftsOnSiteChart]: 'people-overview.number-of-crafts-on-site',
    [PeopleOverviewChart.TimeUtilizationChart]: 'people-overview.time-utilization',
    [PeopleOverviewChart.HrsWorkedByAreaChart]: 'people-overview.hours-worked-by-area',
};

export const CommonGeofenceChartOptions: Partial<Highcharts.Options> = {
    chart: { height: 130, width: 185 },
    legend: { enabled: false },
    plotOptions: { pie: { innerSize: 80, size: 100 } },
};

export interface GeofenceChartData extends PointOptionsObject {
    key: string;
    chartKey: string;
}

/** How we aggregate data to prepare for highcharts */
export interface SitePeopleChartData extends PointOptionsObject {
    key?: string;
    geofences?: { [key: string]: GeofenceChartData; };
    peopleIds?: { [key: string]: string; };
    dataKeys?: {[key: string]: string}; // used in "other" category
}

export interface SitePeopleGeofenceLog {
    id: string;
    entered: number;
    exited?: number;
    personId: string;
    geofenceCategories?: string[];
    geofenceTypeId?: string;
    geofenceId?: string;
}

export interface HoursCache {
    [personId: string]: {
        geofences: { [id: string]: number; };
        categories: { [id: string]: number; };
        types: { [id: string]: number; };
        total: number;
    };
}

export interface PeopleRowCache {
    people: { [id: string]: { [key: string]: SitePeopleGeofenceLog } }; // people on site for selected time
    geofences: { [id: string]: number }; // count of people in geofence at selected time
    rows: { [id: string]: SitePeopleGeofenceLog };
    exited: HoursCache;
    hours: HoursCache; // aggregated hrs day for entire time range
    personnel: Array<{
        personIds: {[key: string]: string};
        geofences: {[key: string]: any};
        time: number;
        count: number;
    }>;
    lastTime: number;
}


export interface CraftsChartData extends PointOptionsObject {
    key: string;
    chartKey: string;
}

export interface CompaniesChartData extends PointOptionsObject {
    chartKey: string;
}

export function roundedHrsLabel(value: number, decimalPlace: number = 1): string {
    return `${Math.round(value * decimalPlace) / decimalPlace}hr(s)`;
}
