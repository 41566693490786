/**
 * Converts a date from a string or number to a Date
 * 
 * We could just do `new Date()` but this is a little more explicit,
 * and I kept having to double-check if `new Date()` would work the
 * way I expected, because javascript has a habit of doing funny things.
 * 
 * And it handles null/undefined well.
* 
 * @param dateLike A date, an ISO string, or a Unix epoch
 * @returns 
 */
export function coerceToDate(dateLike: Date | string | number | undefined | null): Date | undefined {
    if (!dateLike && dateLike !== 0) return undefined;
    return new Date(dateLike);
}
