export enum Language {
    en = 'en',
    es = 'es',
    pt = 'pt',
    de = 'de',
    it = 'it',
    fr = 'fr',
    my = 'my',
    sw = 'sw',
    sq = 'sq',
    ko = 'ko',
    th = 'th',
    fil = 'fil',
    ps = 'ps',
    ja = 'ja',
    vi = 'vi',
    zh = 'zh',
    uk = 'uk',
}

export interface Currency {
    code: string;
    name: string;
    fractionSize: number;
    symbol: string;
}
