export class Utils {
    static maybeDecimal(value: number) {
        value = Number(value);
        if (isNaN(value)) {
            return '';
        }
        if (Math.round(value * 10) % 10 === 0) return `${Math.round(value)}`;
        else return `${Math.round(value * 10) / 10}`;
    }

    static redact(obj: any, allowedKeys: RegExp): unknown[] | Record<string, unknown> | undefined {
        if (Array.isArray(obj)) {
            const result = [];
            for (let i = 0; i < obj.length; i++) {
                const redacted = Utils.redact(obj[i], allowedKeys);
                result.push(redacted === undefined ? !!obj[i] : redacted);
            }
            return result;
        } else if (obj && typeof obj === 'object') {
            const result: Record<string, unknown> = {};
            Object.keys(obj).forEach(key => {
                const val = obj[key];
                if (allowedKeys.test(key)) {
                    result[key] = val;
                } else {
                    const redacted = Utils.redact(val, allowedKeys);
                    result[key] = redacted === undefined ? !!val : redacted;
                }
            });
            return result;
        }
        return undefined;
    }
}
