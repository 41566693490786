import { Subject } from 'rxjs';
import { Structure } from '../models/structure.model';
import { PermissionAction } from '../permissions/permissions.model';
import { Utils } from '../utils/utils';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';
import { StructureService } from './structure.service';

export class StructureStateService extends StateServiceBase {
    structures: Map<string, Structure> = new Map();
    structures$: Subject<StateUpdate<Structure>> = new Subject();

    constructor(
        private structureService: StructureService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)) return;
        this.structures = Utils.toMap(await this.structureService.getAll(c, facilityId, tags));
    }

    async stop() {
        super.stop();
        this.structures.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)) return;

        return (await this.structureService.subscribeStructureUpdates(c)).subscribe(async result => {
            const structureMap = result.payload;
            this.updateFromMap(this.structures, structureMap, this.structures$);
        });
    }
}
