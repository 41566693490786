<div appClickOutside
    class="options-container"
    (clickOutside)="showOptionList(false)"
>
    <ng-container *ngTemplateOutlet="hasPrimaryAction ? primaryAction : noPrimaryAction"></ng-container>

    <div *ngIf="optionListOpened"
        class="options-container-list"
        [ngClass]="openDirection"
    >
        <div 
            class="options-container-list-item ellipsis-text"
            [attr.id]="option.id"
            *ngFor="let option of options" 
            (click)="handleOptionClick(option)"
        >
            {{ translate && option.display ? (option.display | translate) : option.display }}
            <i *ngIf="option.icon" class="icon" [ngClass]="option.icon"></i>
        </div>
    </div>
</div>

<ng-template #noPrimaryAction>
    <button (click)="showOptionList()"
        class="button blue-lt"
        [ngClass]="class"
        [disabled]="disabled"
        matTooltip="{{ tooltipTextKey ? (tooltipTextKey | translate) : tooltipText || '' }}"
    >
        <i *ngIf="icon" [ngClass]="icon"></i>
        <span>{{ buttonTextKey ? (buttonTextKey | translate) : buttonText }}</span>
        <i class="far fa-chevron-down mL10"></i>
    </button>
</ng-template>

<ng-template #primaryAction>
    <button (click)="handleClick()"
        id="button-group-primary"
        class="button blue-lt"
        [class.no-border-radius-right]="shouldShowOptions"
        [ngClass]="class"
        [disabled]="disabled"
    >
        {{ buttonTextKey ? (buttonTextKey | translate) : buttonText }}
    </button>

    <button
        *ngIf="shouldShowOptions"
        (click)="showOptionList()"
        [class.no-border-radius-left]="shouldShowOptions"
        [ngClass]="class"
        [disabled]="disabled"
    >
        <i class="far fa-chevron-down"></i>
    </button>
</ng-template>