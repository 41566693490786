<div *ngIf="showLabel && label" class="label-container">
    <span class="input-label" [class.required]="required" *ngIf="label">{{translate ? (label | translate) : label }}</span>
    <app-tooltip *ngIf="tooltip" [options]="tooltip" [translate]="translate"></app-tooltip>
</div>
<mat-form-field
    class="w100p app-chip-list"
    [floatLabel]="floatLabel ? 'auto' : 'never'"
    [ngClass]="{'dark': dark, 'disabled' : isDisabled}"
>
    <mat-chip-list #chipList [required]="required">

        <app-chip *ngFor="let item of selectedItems" [chip]="item" [displayKey]="displayKey" [translate]="translate" (removeOutput)="remove($event)"></app-chip>

        <input #input type="text" [formControl]="inputCtrl"
            [matChipInputFor]="chipList"
            [matAutocomplete]="chipAuto"
            [id]="inputId"
            [disabled]="isDisabled"
            [ngClass]="{ 'disabled' : isDisabled }">
        
        <span *ngIf="placeholder && !inputCtrl.value && selectedItems.length === 0" class="placeholder">{{ placeholder | translate }}</span>

        <mat-autocomplete #chipAuto autoActiveFirstOption [displayWith]="displayFn" (optionSelected)="addItem($event)">
            <mat-option *ngIf="(filteredItems | async)?.length < 1" disabled>
                {{ (emptyOptionText ? emptyOptionText : 'generics.empty-options') | translate }}
            </mat-option>
            <mat-option appPreventBlur disableRipple *ngFor="let item of filteredItems | async" [ngClass]="{'hidden': item.hidden}" [value]="item" [disabled]="!removeSelected && itemIsSelected(item) ? '' : null">
                <span *ngIf="!removeSelected && itemIsSelected(item)"><i class="fas fa-check mR5"></i></span>
                <span *ngIf="translate">{{item[displayKey] | translate}}</span>
                <span *ngIf="!translate">{{item[displayKey]}}</span>
            </mat-option>
            <mat-option *ngIf="canAdd && inputCtrl.value && (filteredItems | async)?.length < 1" appPreventBlur disabled class="add-new-item" (click)="addNewItem(inputCtrl.value)">
                <span class="show w100p h100p cPointer">{{ (addNewItemLabel ? addNewItemLabel : 'shared.generics.add-item') | translate:{item:inputCtrl.value} }}</span>
            </mat-option>
            <mat-option *ngIf="hasMoreOptions && !inputCtrl.value" appPreventBlur disabled>
                {{ 'shared.generics.search-for-more-options' | translate }}
            </mat-option>
        </mat-autocomplete>

    </mat-chip-list>
</mat-form-field>
<div *ngIf="showErrors" class="error-container">
    <mat-error *ngIf="formControl?.hasError('required') && formControl?.touched">{{ 'formValidation.required' | translate:{ label: label | translate } }}</mat-error>
    <mat-error *ngIf="formControl?.hasError('invalidFacilitiesWorkflow')">{{ 'configuration.workflows.invalid-facilities' | translate }}</mat-error>
    <mat-error *ngIf="formControl?.hasError('invalidFacilitiesForm')">{{ 'configuration.forms.invalid-facilities' | translate }}</mat-error>
</div>

