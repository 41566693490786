import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateRange, DvrControlsPlaybackState, DvrControlsPlayingState } from 'weavix-shared/models/dvr.model';

@Injectable({
    providedIn: 'root',
})
export class DvrControlsService {

    constructor() {
        this.reset();
    }

    set inPlaybackMode(inPlaybackMode: boolean) { this.playbackState$.value.inPlaybackMode = inPlaybackMode; }
    get inPlaybackMode() { return this.playbackState$.value.inPlaybackMode; }
    get isPlaying() { return this.playingState$.value.isPlaying; }

    get timestamp() { return this.timestamp$?.value ?? 0; }
    timestamp$: BehaviorSubject<number>;

    get sliderMoving() { return this.sliderMoving$?.value ?? false; }
    sliderMoving$: BehaviorSubject<boolean>;

    playingState$: BehaviorSubject<DvrControlsPlayingState>;
    playbackState$: BehaviorSubject<DvrControlsPlaybackState>;
    dvrRangeChange$: BehaviorSubject<{ range: DateRange }>;

    reset() {
        this.timestamp$ = new BehaviorSubject(this.timestamp);
        this.sliderMoving$ = new BehaviorSubject(false);
        this.playingState$ = new BehaviorSubject({ isPlaying: false, timestamp: this.timestamp });
        this.playbackState$ = new BehaviorSubject({ inPlaybackMode: false, timestamp: this.timestamp });
        this.dvrRangeChange$ = new BehaviorSubject({} as any);
    }

    updateTimestamp(timestamp: number) {
        this.timestamp$.next(timestamp);
        this.updatePlaybackState({ ...this.playbackState$.value, timestamp });
    }

    updatePlaybackState(state: DvrControlsPlaybackState) {
        this.playbackState$.next(state);
    }

    updatePlaybackPlayState(state: DvrControlsPlayingState) {
        this.playingState$.next(state);
    }
}
