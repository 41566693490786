import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectGuard, redirectGuardFn } from 'console/app/core/guards/redirect/redirect-guard.service';
import { environment } from 'environments/environment';
import { CoreTemplateComponent } from './core-template/core-template.component';

const routes: Routes = [
    {
        path: 'login',
        canActivate: [redirectGuardFn],
        // Yes, this is intentional as NG will throw an error w/out some minimum properties set
        component: RedirectGuard,
        data: {
            externalUrl: `${environment.radioUrl}/login/authorize?returnApp=console`,
        },
    },
    {
        path: 'login/authorize',
        redirectTo: 'login',
    },
    {
        path: 'a/:accountName',
        children: [
            {
                path: '',
                loadChildren: () => import('../console/console.module').then(m => m.ConsoleModule),
            },
            {
                path: '**',
                loadChildren: () => import('../console/console.module').then(m => m.ConsoleModule),
            },
        ],
    },
    {
        path: 'util',
        loadChildren: () => import('../util/util.module').then(m => m.UtilModule),
    },
    {
        path: '**',
        component: CoreTemplateComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        enableTracing: environment.enableRouteTracing,
    })],
    exports: [RouterModule],
})

export class AppCoreRoutingModule {}
