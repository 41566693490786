import { Component, Input } from '@angular/core';
import { Person } from 'weavix-shared/models/person.model';
import { TranslationService } from 'weavix-shared/services/translation.service';

@Component({
    selector: 'app-location-details',
    templateUrl: './location-details.component.html',
    styleUrls: ['./location-details.component.scss'],
})
export class LocationDetailsComponent {
    @Input() person: Person;
    constructor(private translationService: TranslationService) { }

    componentDidMount(): void {
    }

    getDisplayLocationType(input: string) {
        switch (input) {
            case ('combain'):
            case ('openCellId'):
            case ('external'):
                return this.translationService.getImmediate('configuration.user.gps-assist-location');
            case ('beacon'): return this.translationService.getImmediate('configuration.user.beacon-location');
            case ('gps'): return this.translationService.getImmediate('configuration.user.gps-location');
            case ('network'): return this.translationService.getImmediate('configuration.user.network-location');
            case ('unpinned-network'): return this.translationService.getImmediate('configuration.user.unpinned-network-location');
            case ('cell'): return this.translationService.getImmediate('configuration.user.cell-location');
            case ('wisp'): return this.translationService.getImmediate('configuration.user.wisp-location');
            default: return input;
        }
    }

    getDisplayDeviceType() {
        if (this.person.badge?.locationType === 'wisp') return 'Wisp';
        if (this.person.badge?.deviceType === 'ios') return 'iOS';
        if (this.person.badge?.deviceType === 'android') return 'Android';
        return this.person.badge?.deviceType;
    }

    getLocationPermissions() {
        if (this.person?.badge?.locationType === 'wisp' || this.person?.badge?.deviceType === 'walt') return '';
        const grantedPermissions = this.person?.badge?.grantedLocationPermissions;
        if ((!grantedPermissions?.fine && !grantedPermissions?.coarse) || !grantedPermissions?.background) {
            return this.translationService.getImmediate('configuration.user.insufficient-location-permissions');
        }
    }

    isLocationDelayed() {
        return new Date().getTime() - new Date(this.person?.badge?.locationDate).getTime() > 300000;
    }

    getLocationAccuracy() {
        return `${Math.ceil(this.person.badge?.locationDetails?.accuracy)}m`;
    }

    getContributorIcon(type: string) {
        switch (type) {
            case ('beacon'): return 'fa fa-crosshairs';
            case ('cell'): return 'fa fa-signal';
            case ('wifi'): return 'fa fa-wifi';
            default: return 'fa fa-compass';
        }
    }

}
