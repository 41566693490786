<app-loading [loading]="isLoading" [theme]="'transparent'"></app-loading>

<div *ngIf="!isLoading" class="wrapper flex1 flexColumn jcFS">
    <div class="flexColumn jcC h100p w100p" *ngIf="!item">
        <p class="no-data">{{'ERRORS.GENERIC.LOAD-DATA' | translate}}</p>
    </div>

    <ng-container *ngIf="item">
        <div (click)="handleClose()" class="intro">
            <app-icon
                class="intro-close"
                [faIcon]="'fa fa-chevron-left'"
            ></app-icon>

            <p *ngIf="item.name" class="intro-name">{{item.name}}</p>
        </div>
    </ng-container>

    <div class="flexColumn jcFS">
        <ng-container *ngIf="item && itemType">
            <form *ngIf="form" [formGroup]="form">
                <ng-container *ngTemplateOutlet="imageIconDisplay"></ng-container>

                <ng-container *ngIf="itemType?.dynamicMapItem">
                    <ng-container *ngTemplateOutlet="dynamicMapItemFormSection; context: {form: form}"></ng-container>
                </ng-container>

                <ng-container *ngTemplateOutlet="itemPropertiesFormSection; context: {form: form}"></ng-container>
            </form>
        </ng-container>

        <div class="flexColumn h100p w100p jcC" *ngIf="!item">
            <p class="no-data">{{'search.error' | translate}}</p>
        </div>
    </div>
    
    <app-event-log
        *ngIf="badgeEventsHistory && itemType?.dynamicMapItem"
        class="flexColumn pT15 h100p"
        [badgeEvents]="badgeEvents.asObservable()" 
        [badgeEventsHistory]="badgeEventsHistory"
        [rowEventTypes]="rowEventTypes"
        [nameLookUp]="eventNameLookUp()"
    ></app-event-log>
</div>

<ng-template #imageIconDisplay>
    <div class="image-container" *ngIf="itemType?.dynamicMapItem">
        <app-image-upload
            [imageSrc]="item?.image"
            [readonly]="!hasUpdatePermission || submittingChanges"
            [iconPlaceHolder]="{'faIcon': itemType?.icon.value, 'fontSize': '100px', color: css.colors.WHITE}"
            (imageChange)="handleImageChange($event)"
        ></app-image-upload>
    </div>

    <div class="icon-container" *ngIf="!itemType?.dynamicMapItem && itemType?.icon?.value">
        <app-icon [icon]="{'faIcon': itemType?.icon.value, 'fontSize': '100px', color: css.colors.WHITE}"></app-icon>
    </div>
</ng-template>

<ng-template #dynamicMapItemFormSection let-form="form">
    <div [formGroup]="form">
        <app-dropdown
            floatedLabel="shared.items.owner"
            formControlName="owner"
            [customWidth]="220"
            [dropdownInput]="companiesInput"
            [setDefault]="false"
            [required]="true"
            [readonly]="!hasUpdatePermission || submittingChanges"
            class="vertical-input-margin"
        ></app-dropdown>
    
        <app-dropdown
            floatedLabel="shared.items.assigned-to"
            formControlName="assignedToId"
            [customWidth]="220"
            [dropdownInput]="peopleInput"
            [setDefault]="false"
            [required]="true"
            [readonly]="!hasUpdatePermission || submittingChanges"
            class="vertical-input-margin"
        ></app-dropdown>
    </div>
</ng-template>

<ng-template #itemPropertiesFormSection let-form="form">
    <div [formGroup]="form.controls.properties">
        <div formArrayName="properties">
            <ng-container *ngFor="let field of itemProperties?.controls; let i = index;" [formGroupName]="i">
                <app-input
                    *ngIf="[fieldType.Text, fieldType.Number].includes(getFieldType(field?.controls?.fieldId?.value))"
                    [label]="field?.controls?.key?.value"
                    formControlName="value"
                    [isDisabled]="!hasUpdatePermission || submittingChanges"
                    class="vertical-input-margin"
                ></app-input>   

                <app-dropdown 
                    *ngIf="getFieldType(field?.controls?.fieldId?.value) === fieldType.Dropdown" 
                    [floatedLabel]="field?.value?.key"
                    [setDefault]="false"
                    [customWidth]="220"
                    [dropdownInput]="getFieldOptions(field?.controls?.fieldId?.value)"
                    formControlName="value"
                    [readonly]="!hasUpdatePermission || submittingChanges"
                    class="vertical-input-margin"
                ></app-dropdown>
            </ng-container>
        </div>
    </div>
</ng-template>