import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SharedModule } from 'shared/shared.module';
import { AppCoreRoutingModule } from './core-routing.module';
import { CoreTemplateComponent } from './core-template/core-template.component';
import { LoginPageTemplateComponent } from './login-page-template/login-page-template.component';
import { createTranslateLoader } from 'weavix-shared/services/translation.service';

@NgModule({
    declarations: [
        CoreTemplateComponent,
        LoginPageTemplateComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AppCoreRoutingModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        DeviceDetectorModule.forRoot(),
    ],
    providers: [
        /* Use @Injectable({ providedIn: 'root' }) */
        CookieService,
        { provide: Document, useExisting: DOCUMENT },
    ],
    exports: [RouterModule, SharedModule],
})
export class AppCoreModule {}
