import { Component, Input, OnInit } from '@angular/core';
import { CalloutOptions } from 'components/callout/callout.component';
import { environment } from 'environments/environment';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { css } from 'weavix-shared/utils/css';

export interface MapControl {
    id: string;
    onClick?: () => void;
    tooltip: string;
    icon: string;
    iconHover?: string;
    isActive?: () => boolean;
    hide?: () => boolean;
    color?: string;
    callout?: CalloutOptions;
    options?: MapControl[];
}

@Component({
    selector: 'app-map-control',
    templateUrl: './map-control.component.html',
    styleUrls: ['./map-control.component.scss', './map-control-teams.component.scss'],
})
export class MapControlComponent implements OnInit {
    teamsApp = environment.teamsApp;
    @Input() control: MapControl;
    displayOptions: boolean = false;
    colors = css.colors;
    buttonHovers: { [id: string]: boolean } = {};

    constructor(public translateService: TranslationService) {}

    ngOnInit() {
        if (this.teamsApp) this.control.color = null;
    }

    toggleOptions() {
        this.displayOptions = !this.displayOptions;
    }

    closeOptions(): void {
        this.displayOptions = null;
    }

    handleControlOptionClick(option: MapControl): void {
        option?.onClick();
    }
}
