import { coerceToDate } from './coerce-to-date';

// Dates and strings are considered dates. Numbers are not dates, just numbers.
type MaxLike = number | Date | string;

/**
 * A function like Math.max() that emulates the $max capability of MongoDB.
 * https://www.mongodb.com/docs/manual/reference/operator/update/max/
 * It can compare numbers and dates, versus Math.max() can only do numbers.
 * 
 * It can also compare ISO-8601 strings because dates are serialized to strings in
 * JSON, which we don't consistently marshal back to dates on the client side.
 * Usually our date types are actually strings.
 * 
 * @param first First value to compare
 * @param second Second value to compare
 * @returns The higher value
 */
export function mongoDbMax(first: MaxLike, second: MaxLike): MaxLike | null {
    if (nullish(first) && nullish(second)) return null;
    if (nullish(first) && validMaxLike(second)) return second;
    if (nullish(second) && validMaxLike(first)) return first;

    if (!validMaxLike(first) || !validMaxLike(second)) {
        throw new Error(`Invalid input.` +
            ` Params must be number, date, or ISO-8601 string.` +
            ` Got: ${first} and ${second}`);
    }

    // taking a small chance here - if one param is a date, assume the return type should be a date.
    // maybe we should always return a Date no matter the inputs? hard to tell the impact.
    // this is used for the sequence value of read messages, so probably needs to be smart about it like it is...
    if (first instanceof Date || second instanceof Date) {
        const firstDate = coerceToDate(first)!;
        const secondDate = coerceToDate(second)!;
        return firstDate > secondDate ? firstDate : secondDate;
    }

    if (typeof first === 'number' && typeof second === 'number') {
        return Math.max(first as number, second as number);
    }

    if (typeof first === 'string' && typeof second === 'string') {
        const firstDate = new Date(first);
        const secondDate = new Date(second);
        // return as a string since that's what the input was
        return firstDate > secondDate ? first : second;
    }

    throw new Error(`Invalid input.` +
        ` Both params must be same type. Params must be number, date, or ISO-8601 string.` +
        ` Got: ${first} (${getType(first)}) and ${second} (${getType(first)})`);
}

function validMaxLike(value: MaxLike): boolean {
    return value instanceof Date ||
        typeof value === 'number' ||
        typeof value === 'string';
}

function nullish(value: unknown): boolean {
    return value === null || value === undefined;
}

function getType(value: unknown): string {
    if (value === null) return 'null';
    if (typeof value === 'object') return value.constructor.name;
    return typeof value;
}
