import { Context, createStore, ExtendedModel, model } from '@weavix/mobx';
import { myUserId } from '../my-profile-store/my-profile-store';
import { FacilityPerson } from './facility-people.model';

const base = createStore<FacilityPerson>('facility-people', FacilityPerson);
const getFullId = (facilityId, personId) => `${facilityId}-${personId}`;

export const facilityPeopleContext = base.context as Context<FacilityPeopleStore>;
export const facilityPeopleRef = base.ref;

export const getFacilityPerson = (facilityId: string, personId = myUserId()) => {
    return facilityPeopleContext.get().get(getFullId(facilityId, personId));
};

@model('FacilityPeopleStore')
export class FacilityPeopleStore extends ExtendedModel(base.Store, {
}) {
    getPerson(personId: string, facilityId: string) {
        return this.getStatic(getFullId(facilityId, personId));
    }
}
