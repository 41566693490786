import { MqttStorage } from '@weavix/mqtt';
import { LazyStorage } from './lazy-storage';

export class WebMqttStorage implements MqttStorage {
    private messages: LazyStorage<string>;

    constructor(type: 'incoming' | 'outgoing') {
        this.messages = new LazyStorage(`mqtt-${type}`);
    }

    clear() {
        this.messages.clear();
    }

    async get(key: string) {
        return this.messages.get(key);
    }

    set(key: string, packet: string) {
        this.messages.set(key, packet);
    }

    async getAll() {
        return Object.values(await this.messages.map());
    }

    remove(key: string): void {
        this.messages.remove(key);
    }
}
