import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChipListService {
    private newItemSource$ = new Subject<any>();

    constructor() {}

    get newItem$(): Observable<any> {
        return this.newItemSource$.asObservable();
    }

    onNewItem(newItem: any): void {
        this.newItemSource$.next(newItem);
    }
}
