
<ng-container *ngFor="let button of buttons">
    <button class="list-button"
        [ngClass]="{
            'selected': button.selected,
            'disabled': button.disabled
        }"
        [ngStyle]="{ 
            'background-color': options?.deselectedButtonColor && !button.selected && !button.disabled ? options.deselectedButtonColor : null,
            'color': options?.deselectedTextColor && !button.selected && !button.disabled ? options.deselectedTextColor : null
        }"
        (click)="selectButton(button.keyValue, $event)"
    >
        <app-icon
            *ngIf="button.icon"
            class="pR10"
            [faIcon]="button.icon"
        ></app-icon>
    
        <p class="text">{{button.textKey | translate}}</p>
    </button>
</ng-container>
