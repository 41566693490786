import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Dashboard, DashboardWidgetSelection, DashboardWidgetType, WidgetAccessLevel } from '../models/dashboard.model';
import { DataSourceCondition, DataSourceTable, DataSourceValue, DataSourceVariable } from '../models/data-source.model';
import { Utils } from '../utils/utils';
import { AlertService } from './alert.service';
import { FacilityService } from './facility.service';
import { CacheContext, HttpService } from './http.service';

const ROOT_FOLDER = 'root-folder';
@Injectable({ providedIn: 'root' })
export class DashboardService implements OnDestroy {

    constructor(
        private httpService: HttpService,
        private facilityService: FacilityService,
        private alertService: AlertService,
    ) {
    }

    get currentDashboard() {
        return this.currentDashboard$.pipe(take(1)).toPromise();
    }

    static baseUrl = '/core/dashboards';
    static cacheCollection = 'dashboards';

    private loadingWidgets: any[] = [];

    private widgetSelections: DashboardWidgetSelection[] = [
        {
            type: DashboardWidgetType.BarChart,
            faIcon: 'fas fa-chart-bar',
            formName: 'dashboard.widget.barChart.name',
            formTitle: 'dashboard.widget.barChart.formTitle',
        },
        {
            type: DashboardWidgetType.PieChart,
            faIcon: 'fas fa-chart-pie',
            formName: 'dashboard.widget.pieChart.name',
            formTitle: 'dashboard.widget.pieChart.formTitle',
        },
        {
            type: DashboardWidgetType.LineChart,
            faIcon: 'fas fa-chart-line',
            formName: 'dashboard.widget.lineChart.name',
            formTitle: 'dashboard.widget.lineChart.formTitle',
        },
        {
            type: DashboardWidgetType.Count,
            faIcon: 'fas fa-tally',
            formName: 'dashboard.widget.count.name',
            formTitle: 'dashboard.widget.count.formTitle',
        },
        {
            type: DashboardWidgetType.Table,
            faIcon: 'fas fa-table',
            formName: 'dashboard.widget.table.name',
            formTitle: 'dashboard.widget.table.formTitle',
        },
        {
            type: DashboardWidgetType.RichText,
            faIcon: 'far fa-pen-square',
            formName: 'dashboard.widget.richText.name',
            formTitle: 'dashboard.widget.richText.formTitle',
        },
    ];
    private cacheContext: CacheContext = { collection: DashboardService.cacheCollection, maxAge: 1800000 };

    currentDashboard$: Subject<Dashboard> = new ReplaySubject<Dashboard>(1);
    static url = (id?: string) => id ? `${DashboardService.baseUrl}/${id}` : DashboardService.baseUrl;
    static dashboardUrl = (id?: string) => id ? `${DashboardService.baseUrl}/${id}` : DashboardService.baseUrl;

    ngOnDestroy() {}

    getDashboards(component: any, facilityId?: string, tags?: string[]) {
        return this.httpService.get<Dashboard[]>(component, DashboardService.dashboardUrl(), { facilityId, tags });
    }

    getDashboard(component: any, id: string) {
        return this.httpService.get<Dashboard>(component, `${DashboardService.dashboardUrl(id)}`);
    }

    addDashboard(component: any, dashboard: Dashboard, clearCache: boolean = true) {
        if (clearCache) {
            HttpService.clearCache(this.cacheContext.collection);
        }
        if (dashboard.folderId === ROOT_FOLDER) dashboard.folderId = null;
        return this.httpService.post<Dashboard>(component, DashboardService.dashboardUrl(), dashboard);
    }

    updateDashboard(component: any, dashboard: Partial<Dashboard>, clearCache: boolean = true) {
        if (clearCache) {
            HttpService.clearCache(this.cacheContext.collection);
        }
        if (dashboard.folderId === ROOT_FOLDER) dashboard.folderId = null;
        return this.httpService.put<Dashboard>(component, `${DashboardService.dashboardUrl(dashboard.id)}`, dashboard);
    }

    deleteDashboard(component: any, id: string, clearCache: boolean = true) {
        if (clearCache) {
            HttpService.clearCache(this.cacheContext.collection);
        }
        return this.httpService.delete<Dashboard>(component, `${DashboardService.dashboardUrl(id)}`);
    }

    getCurrentDashboard() {
        return this.currentDashboard;
    }

    // This is just a shortcut until we implement proper dashboard on the front-end
    async getCurrentDashboards(component: any, facilityId?: string) {
        if (!facilityId) {
            facilityId = (await this.facilityService.getCurrentFacility())?.id || 'root';
        }
        const dashboards = await this.getDashboards(component, facilityId);
        return Utils.sortAlphabetical(dashboards, x => x.name);
    }

    getDashboardWidgetSelections(): DashboardWidgetSelection[] {
        const facility = this.facilityService.getCurrentFacility();
        let filter: WidgetAccessLevel = WidgetAccessLevel.Account;
        if (facility) {
            filter = WidgetAccessLevel.Facility;
        }
        const widgets = this.widgetSelections.filter(w => (!w.accessLevels || w.accessLevels[filter] === undefined || w.accessLevels[filter] !== false));
        return Utils.sortAlphabetical(widgets, (item) => item.type);
    }

    getDashboardWidgetSelection(type: DashboardWidgetType): DashboardWidgetSelection {
        return this.widgetSelections.find(w => w.type === type);
    }

    getWidgetIcon(widgetType: DashboardWidgetType): string {
        const widgetSelection: DashboardWidgetSelection = this.widgetSelections.find(w => w.type === widgetType);
        return _.get(widgetSelection, 'faIcon', null);
    }

    async getWidgetsVariables(component: any, dashboard: Dashboard) {
        const variablesMap: {[key: string]: DataSourceVariable} = {};

        const findVariables = async (from: DataSourceTable) => {
            if (!from) return;

            if (from.query) {
                const addSelect = (x: DataSourceValue) => {
                    if (x.variable && !variablesMap[x.variable]) variablesMap[x.variable.toUpperCase()] = { name: x.variable.toUpperCase(), value: null };
                };
                const addWhere = (condition: DataSourceCondition) => {
                    (condition.conditions || []).forEach(addWhere);
                    (condition.values || []).forEach(addSelect);
                };
                (from.query.where || []).forEach(addWhere);
                (from.query.select || []).forEach(addSelect);

                await Promise.all((from.query.from || []).map(async f => {
                    await findVariables(f);
                }));
            } else if (from.union) {
                await Promise.all(from.union.map(async f => {
                    await findVariables(f);
                }));
            } else if (from.sql) {
                for (let i = from.sql.indexOf('{{'); i >= 0; i = from.sql.indexOf('{{', i + 2)) {
                    const end = from.sql.indexOf('}}', i + 2);
                    if (end === -1) return;
                    const found = from.sql.substring(i + 2, end);
                    variablesMap[found.toUpperCase()] = { name: found.toUpperCase(), value: null };
                    i = end + 2;
                }
            }
        };
        await Promise.all((dashboard.sources || []).map(async w => {
            findVariables(w.source);
        }));
        delete variablesMap['FACILITYID'];
        delete variablesMap['TIMEZONE'];
        delete variablesMap['PERSONID'];
        return Object.values(variablesMap);
    }

    setWidgetLoading(component: any, loading: boolean) {
        if (loading && !this.loadingWidgets.includes(component)) this.loadingWidgets.push(component);
        if (!loading) this.loadingWidgets = this.loadingWidgets.filter(w => w !== component);
        this.alertService.setAppLoading(!!this.loadingWidgets.length);
    }

}
