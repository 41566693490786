import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TeamsAccountSettings } from '../models/teams.model';

import { CacheContext, HttpService } from './http.service';
import { myUser } from 'models-mobx/users-store/users-store';

interface BasicTeamsGraphResponse {
    id: string;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class TeamsService {
    static teamsOnlyUser = () => myUser().teamsOnlyUser;

    constructor(
        private httpService: HttpService,
    ) {}

    static cacheCollection = 'Keys';
    static baseUrl = '/teams';
    private readonly cacheContext: CacheContext = { collection: TeamsService.cacheCollection, maxAge: 1800000 };
    static url = (endpoint?: string) => endpoint ? `${TeamsService.baseUrl}/${endpoint}` : TeamsService.baseUrl;

    requestConsent(component: any, accountId: string) {
        return window.open(environment.is360Api + TeamsService.url(`consent/request?state=${accountId}`), 'microsoftAuth', 'popup,width=650,height=650');
    }

    async getOrganization(component: any): Promise<BasicTeamsGraphResponse> {
        return this.httpService.get<BasicTeamsGraphResponse>(component, TeamsService.url('organization'));
    }

    async getSettings(component: any): Promise<TeamsAccountSettings> {
        return this.httpService.get<TeamsAccountSettings>(component, TeamsService.url('settings'));
    }

    async setSettings(component: any, data: TeamsAccountSettings): Promise<TeamsAccountSettings> {
        return this.httpService.post<TeamsAccountSettings>(component, TeamsService.url('settings'), data, this.cacheContext);
    }

    async sync(component: any): Promise<void> {
        return this.httpService.post<void>(component, TeamsService.url('sync'), {}, this.cacheContext);
    }

    async getUsers(component: any): Promise<BasicTeamsGraphResponse[]> {
        return this.httpService.get<BasicTeamsGraphResponse[]>(component, TeamsService.url('users'));
    }

    async getGroups(component: any): Promise<BasicTeamsGraphResponse[]> {
        return this.httpService.get<BasicTeamsGraphResponse[]>(component, TeamsService.url(`groups`));
    }

    async searchAdGroups(component: any, searchText?: string, groupIds?: string[]): Promise<BasicTeamsGraphResponse[]> {
        return this.httpService.post<BasicTeamsGraphResponse[]>(component, TeamsService.url('groups/search'), { searchText, groupIds });
    }

    async disconnect(component: any): Promise<void> {
        return this.httpService.get<void>(component, TeamsService.url('disconnect'));
    }

    async subscription(component: any, data: any): Promise<void> {
        return this.httpService.post<void>(component, TeamsService.url('subscription'), data, this.cacheContext);
    }

    public isTeamsApp(): boolean {
        return environment.teamsApp;
    }

    public isTeamsOnlyUser(): boolean {
        return TeamsService.teamsOnlyUser();
    }
}
