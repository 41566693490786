import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from 'weavix-shared/models/company.model';
import { Item, ItemType } from 'weavix-shared/models/item.model';
@Component({
    selector: 'app-item-row-display',
    templateUrl: './item-row-display.component.html',
    styleUrls: ['./item-row-display.component.scss'],
})

export class ItemRowDisplayComponent {
    @Input() item: Item & { itemType: ItemType, owner: Company };
    @Output() clickEmit: EventEmitter<Item> = new EventEmitter();

    click() {
        this.clickEmit.emit(this.item);
    }
}
