import { Injectable } from '@angular/core';
import { FloorPlan } from '../models/floor-plan.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { AccountService } from './account.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';

@Injectable({
    providedIn: 'root',
})
export class FloorPlanService {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
    ) { }

    private static readonly cacheContext: CacheContext = { collection: 'FloorPlans', maxAge: 1800000 };

    static url = (id?: string) => id ? `/track/floor-plans/${id}` : `/track/floor-plans`;

    public static clearCache = () => HttpService.clearCache(FloorPlanService.cacheContext.collection);

    async getAll(component: any, facilityId?: string, tags?: string[]) {
        return this.httpService.get<FloorPlan[]>(component, FloorPlanService.url(), { tags, facilityId }, FloorPlanService.cacheContext);
    }

    async add(component: any, level: FloorPlan) {
        return this.httpService.post<FloorPlan>(component, FloorPlanService.url(), level, FloorPlanService.cacheContext);
    }

    async update(component: any, id: string, level: FloorPlan) {
        return this.httpService.put<FloorPlan>(component, FloorPlanService.url(id), level, FloorPlanService.cacheContext);
    }

    async delete(component: any, id: string) {
        return this.httpService.delete<void>(component, FloorPlanService.url(id), null, FloorPlanService.cacheContext);
    }

    async subscribeFloorPlanUpdates(c: any) {
        return this.pubSubService.subscribe<{[key: string]: FloorPlan}>(c, Topic.AccountMapFloorPlans, [this.accountService.getAccountId(), '+']);
    }
}
