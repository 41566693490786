
export interface CacheOptions {
    duration: number;
    jitter?: number; // to avoid cache entries expiring at the same time, can add some jitter
}

export class Cache<T> {
    constructor(private options: CacheOptions) {}

    private map = new Map<string, { value: T; expires: Date; }>();
    get(id: string) {
        const entry = this.map.get(id);
        if (entry?.expires && entry.expires.getTime() > new Date().getTime()) return entry.value;
        return undefined;
    }

    set(id: string, value: T) {
        const expires = new Date(new Date().getTime() + this.options.duration + Math.random() * (this.options.jitter ?? 0));
        this.map.set(id, { value, expires });
        return value;
    }

    clear(id: string) {
        this.map.delete(id);
    }
}
