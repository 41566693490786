<img class="logo-loader"
    src="assets/img/logo-no-text.png" 
    [ngClass]="{ 'slow': slow }"
    [ngStyle]="{ 'width.px': size, 'height.px': size }"
    *ngIf="!teamsService.isTeamsApp()"
>
<div *ngIf="teamsService.isTeamsApp()" style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
    <mat-progress-spinner
        class="teams-spinner"
        [diameter]="diameter"
        mode="indeterminate"
    ></mat-progress-spinner>
</div>
