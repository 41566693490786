import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { TableFilterCategory, TableFilterResult } from 'weavix-shared/models/table.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { TableService } from '../table.service';

export interface TableFilterSearchConfig {
    enabled: boolean;
    category: TableFilterCategory;
    placeholderKey?: string;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
})

export class TableFilterComponent implements OnChanges, OnInit {
    @Input() filterResults: {[key: string]: TableFilterResult} = {};
    @Input() multiCategorySelect = false;

    loading: boolean = false;
    lightTheme: boolean;

    get filterResultsSorted() {
        const filters = Object.values(this.filterResults).sort((a, b) => a.name < b.name ? -1 : 1);
        // filtered options with no children should always be at the bottom of the list
        return filters.filter(f => f.children).concat(filters.filter(f => !f.children));
    }

    constructor(
        public tableService: TableService,
    ) { }

    getSortedChildren(res: TableFilterResult) {
        return Object.values(res.children ?? {}).sort((a, b) => a.name < b.name ? -1 : 1);
    }

    ngOnInit() {
        this.loading = true;
        this.lightTheme = ThemeService.getLightTheme();
        this.loading = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loading = true;
        if (changes.filterResults) {
            this.checkForEmptyFilters();
        }
        this.loading = false;
    }

    handleClearFilters() {
        this.deselectCategory({ children: this.filterResults } as any);
    }

    handleRowClick(parent: TableFilterResult, cat: TableFilterResult, click: boolean, depth: number): void {
        if (!cat) return;

        if (!this.multiCategorySelect) this.deselectCategory(parent, cat);
        if (depth > 0 && !parent.multiselect) this.deselectCategory(parent, cat);
        if (cat?.selected || click) this.deselectCategory(cat);
        cat.selected = click ? true : !cat.selected;
        cat.setSelected?.(cat.selected, true, click);
    }

    deselectCategory(cat: TableFilterResult, ignore?): void {
        if (cat.children) {
            Object.values(cat.children).forEach(subCat => {
                if (subCat === ignore || !subCat.selected) return;
                subCat.selected = false;
                subCat.setSelected?.(subCat.selected);
                subCat.hidden = false;
                this.deselectCategory(subCat);
            });
        }
    }

    checkForEmptyFilters() {
        let allEmpty: boolean = true;
        for (const val of Object.values(this.filterResults)) {
            if (Object.values(val.children).length) {
                allEmpty = false;
                break;
            }
        }

        if (allEmpty) return;
    }

}
