
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import * as moment from 'moment-timezone';
import { Observable, Subject } from 'rxjs';
import { AnyBadgeEvent, EventType, eventTypeToPermissions } from 'weavix-shared/models/event.model';
import { Facility } from 'weavix-shared/models/facility.model';
import { RowItemIcon, RowItemType, TableColumn, TableFilter, TableOptions, TableRow } from 'weavix-shared/models/table.model';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { MapService } from 'weavix-shared/services/map.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { EventService, EVENT_MATCHES, RowEventDefinition, RowEventType } from './event.service';
import { AccountService } from 'weavix-shared/services/account.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss'],
})
export class EventLogComponent implements OnInit, OnChanges {
    @Input() badgeEvents: Observable<AnyBadgeEvent>;
    @Input() badgeEventsHistory: AnyBadgeEvent[] = [];
    @Input() rowEventTypes: RowEventType[] = [];
    @Input() nameColumnTitle: string;
    @Input() minHeight: string = 'auto';
    @Input() maxHeight: number;
    @Input() nameLookUp: <T extends AnyBadgeEvent>(row: T) => string;

    @Output() rowClickOutput: EventEmitter<TableRow> = new EventEmitter();

    private eventsDefinitions: RowEventDefinition[] = [];
    private facility: Facility;

    isLoading: boolean = true;
    tableRows: TableRow[];

    lightTheme: boolean;
    tOptionsSubject = new Subject<TableOptions>();

    constructor(
        private translateService: TranslationService,
        private profileService: ProfileService,
        private facilityService: FacilityService,
        private mapService: MapService,
        private accountService: AccountService,
    ) { }

    async ngOnInit() {
                this.lightTheme = ThemeService.getLightTheme();
        Utils.safeSubscribe(this, this.badgeEvents).subscribe(event => {
            if (!this.hasEventViewPermission(event)) return;

            if (EventService.isValidEvent(event, this.rowEventTypes)) {
                this.tableRows.unshift(<any>event);
            }
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes?.badgeEventsHistory?.firstChange) this.facility = this.facilityService.getCurrentFacility();

        if (changes.rowEventTypes || changes.badgeEventsHistory && changes.badgeEventsHistory.currentValue) {
            this.eventsDefinitions = EventService.getByTypes(this.rowEventTypes);
            await this.setupTable(this.badgeEventsHistory);
            this.isLoading = false;
        }
    }

    handleRowClick(event: TableRow) {
        this.rowClickOutput.emit(event);
    }

    private hasEventViewPermission(e: AnyBadgeEvent): boolean {
        return !eventTypeToPermissions[e.type] || this.profileService.hasPermission(eventTypeToPermissions[e.type], this.facility.id, e.folderId);
    }

    private async setupTable(logData: AnyBadgeEvent[]): Promise<void> {
        this.tableRows = <any[]>logData.filter(e => this.hasEventViewPermission(e) && EventService.isValidEvent(e, this.rowEventTypes));

        this.tOptionsSubject.next(await this.buildTableOptions());
    }

    private async buildTableOptions(): Promise<TableOptions> {
        const getIconTooltipTranslations = (eventDef, row): string => {
            switch (eventDef.eventType) {
                case RowEventType.GeofenceExit:
                case RowEventType.GeofenceEnter:
                case RowEventType.GeofenceDisconnect:
                    return this.translateService.getImmediate(eventDef.eventTitle, { geofenceName: row.geofenceName } );
                case RowEventType.BeaconDisconnect:
                case RowEventType.BeaconEnter:
                case RowEventType.BeaconExit:
                    return row?.beaconLevel !== undefined
                        ? this.translateService.getImmediate(eventDef.eventTitle, { beaconLevel: row?.beaconLevel, beaconName: row.beaconName })
                        : this.translateService.getImmediate(eventDef.eventTitle, { beaconName: row.beaconName });
                case RowEventType.LevelEnter:
                case RowEventType.LevelExit:
                    return this.translateService.getImmediate(eventDef.eventTitle, { level: row.level } );
                default:
                    return this.translateService.getImmediate(eventDef.eventTitle);
            }
        };

        const getIconColor = (eventType: EventType): string => {
            switch (eventType) {
                case EventType.BeaconEnter:
                case EventType.GeofenceEnter:
                case EventType.EntryEnter:
                case EventType.EntryAttendantIn:
                case EventType.PeerEnter:
                case EventType.LevelEnter:
                    return css.brandingColors.W_GREEN;
                case EventType.BeaconExit:
                case EventType.GeofenceExit:
                case EventType.GeofenceDisconnect:
                case EventType.EntryExit:
                case EventType.EntryAttendantOut:
                case EventType.PeerExit:
                case EventType.LevelExit:
                case EventType.MobileLogout:
                    return css.brandingColors.W_RED;
                default:
                    return 'white';
            }
        };

        const getIcon = (row: AnyBadgeEvent): RowItemIcon => {
            const eventDef = EventService.findDefinition(row);
            const tooltip = getIconTooltipTranslations(eventDef, row);
            return eventDef ? { ...eventDef.icon, tooltip, color: getIconColor(row.type) } : null;
        };

        const now = this.mapService.dvrPlaybackState?.inPlaybackMode ? this.mapService.dvrTimestamp : new Date().getTime();
        const current = moment(now).tz(this.facility.timezone).format('M/D h:mm A').split(' ');
        const columns: TableColumn[] = [
            {
                title: this.nameColumnTitle || 'name',
                colKey: 'name',
                type: RowItemType.text,
                sort: { sortable: false, selected: false },
                prefix: getIcon,
                value: (row: AnyBadgeEvent) => this.nameLookUp(row) || this.translateService.getImmediate('unknown'),
                rowItemNgStyle: {
                    'font-size.px': 12,
                    'color': css.colors.GRAY,
                    'justify-content': 'center',
                },
                tooltip: (row: AnyBadgeEvent) => this.nameLookUp(row).replace('<br />', '') || this.translateService.getImmediate('unknown'),
            },
            {
                title: 'generics.activity',
                colKey: 'date',
                type: RowItemType.text,
                sort: { sortable: true, selected: true, sortAsc: false, fn: row => row.date },
                maxWidth: 70,
                value: (row: AnyBadgeEvent) => {
                    const parts = moment(row.date).tz(this.facility.timezone).format('M/D h:mm A').split(' ');
                    return parts[0] === current[0] ? `${parts[1]} ${parts[2]}` : parts[0];
                },
                tooltip: (row: AnyBadgeEvent) => {
                    return moment(row.date).tz(this.facility.timezone).format('M/D h:mm A');
                },
            },
        ];
        const select = {
            rowsDblClickable: false,
            rowsClickable: false,
            showCheckboxes: false,
        };
        const pagination = {
            show: true,
            initPageSize: 7,
            showMoreSize: 7,
        };

        const filters: TableFilter[] = this.eventsDefinitions.map((def: RowEventDefinition) => {
            return {
                title: def.eventTitle,
                selected: false,
                key: EVENT_MATCHES[def.eventType],
                filterFn: (row: TableRow) => EventService.eventMatchesDefinition(row.original, def),
            };
        }).sort(Utils.alphabeticalSorter((filter: TableFilter) => filter.title));

        const isArchived = !!(await this.accountService.getAccount())?.archived?.events;

        return {
            title: {
                string: this.translateService.getImmediate('generics.activity-log'),
                centered: true,
                fontSize: 14,
                paddingLeft: 20,
            },
            keyCol: 'id',
            rowEdits: [],
            filters,
            columns,
            select,
            pagination,
            canAdd: false,
            minHeight: 250,
            tablePadding: 0,
            backgroundColor: css.colors.ALMOST_BLACK,
            hideColumnHeaders: true,
            rowBackgroundColor: 'transparent',
            headerOptions: {
                hide: true,
            },
            endMessageKey: isArchived ? 'configuration.compliance.data-retention.archived' : '',
            endMessageNgStyle: {
                'font-size.px': 10,
                'margin-top.px': 10,
            },
        };
    }
}
