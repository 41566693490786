import { MiscTopic } from './misc-topic';
import { WilmaTopic } from './wilma-topic';

// fancy way of combining enums - export both the const and the type with the same name.
export const Topic = {
    ...WilmaTopic,
    ...MiscTopic,    
};
export type Topic = 
    WilmaTopic | 
    MiscTopic;
