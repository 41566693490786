import { Component, Input, OnInit } from '@angular/core';
import { TeamsService } from 'weavix-shared/services/teams.service';

@Component({
    selector: 'app-logo-spinner',
    templateUrl: './logo-spinner.component.html',
    styleUrls: ['./logo-spinner.component.scss'],
})
export class LogoSpinnerComponent implements OnInit {

    @Input() size: number;
    @Input() diameter = 30;
    @Input() slow: boolean;
    @Input() showMessage = true;

    constructor(public teamsService: TeamsService) { }

    ngOnInit(): void {
    }

}
