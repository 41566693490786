import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AnalyticsService, StAction, StObject } from 'weavix-shared/services/analytics.service';
import { DateRangePickerHeaderNoArrowComponent } from 'components/date-range-picker/date-range-picker-header/date-range-picker-header-no-arrow.component';
import { debounce } from 'lodash';
import * as moment from 'moment-timezone';
import { DateRange, DvrControlsPlaybackState, DvrControlsPlayingState, DvrControlsState, DvrType } from 'weavix-shared/models/dvr.model';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { css } from 'weavix-shared/utils/css';
import { DateTimeUtil } from 'weavix-shared/utils/datetime';
import { AutoUnsubscribe, SimpleChangesTyped, Utils } from 'weavix-shared/utils/utils';
import { DvrControlsService } from 'weavix-shared/services/dvr-controls.service';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';

enum Direction {
    Next = 'next',
    Previous = 'previous',
}

interface RangeValue {
    date: Date;
    label: string;
    icon?: string;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-dvr-controls',
    templateUrl: './dvr-controls.component.html',
    styleUrls: ['./dvr-controls.component.scss'],
})

export class DvrControlsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() type: DvrType = DvrType.Day;
    @Input() allowPlay: boolean = false;
    @Input() collapsible: boolean = true;
    @Input() hideSlider: boolean = false;
    @Input() removeDateSelection: boolean = false;
    @Input() initialTime: number;
    @Input() range: DateRange;
    @Input() treatAsRangeSelection: boolean = false;
    @Input() disabledDatesUntil?: Date;
    @Input() disabledDatesClick?: (date: moment.Moment) => void;
    @Output() playbackStateUpdated: EventEmitter<DvrControlsPlaybackState> = new EventEmitter();
    @Output() playingStateUpdated: EventEmitter<DvrControlsPlayingState> = new EventEmitter();
    @Output() controlsStateUpdated: EventEmitter<DvrControlsState> = new EventEmitter();
    @Output() hideDvr: EventEmitter<void> = new EventEmitter();
    @Output() sliderMoving: EventEmitter<boolean> = new EventEmitter();

    get inPlaybackMode() { return this.playbackState?.inPlaybackMode; }
    get isPlaying() { return this.dvrControlsService.isPlaying; }
    get currentWeek() { return `${this.activeWeek.label} - ${this.activeDay.label}`; }
    get currentRangeLabel() {
        switch (this.type) {
            case DvrType.Custom:
                return this.customRangeLabel;
            case DvrType.Week:
                return this.currentWeek;
            default:
                return this.activeDay.label;
        }
    }

    constructor(
        private translationService: TranslationService,
        private facilityService: FacilityService,
        private dvrControlsService: DvrControlsService,
    ) {}

    customHeader = DateRangePickerHeaderNoArrowComponent;
    DvrType = DvrType;
    playbackState: DvrControlsPlaybackState;
    playingState: DvrControlsPlayingState;
    timestamp: number;
    colors = css.colors;
    isLoading: boolean = true;
    activeDay: RangeValue;
    selectedDate: Date;
    activeWeek: RangeValue;
    rangeTicks: RangeValue[];
    direction = Direction;
    showCalendarPicker: boolean = false;
    sliderRange: {start: number, end: number};
    minDate: Date;
    maxDate: Date = new Date();
    error: boolean = false;
    customRangeLabel: string;
    daysDiff: number;
    hideLiveOption: boolean = false;

    private liveInterval: number;
    private facilityTimezone: string;
    private locale: string;
    private destroyed = false;

    async ngOnInit(): Promise<void> {
        Utils.safeSubscribe(this, this.facilityService.currentFacility$).subscribe(async (facility) => {
            if (!facility) return;
            await this.setupControlsData();
        });

        Utils.safeSubscribe(this, this.dvrControlsService.dvrRangeChange$).subscribe(s => this.handleRangeStateChange(s));
        Utils.safeSubscribe(this, this.dvrControlsService.playbackState$).subscribe(s => this.playbackState = s);
        Utils.safeSubscribe(this, this.dvrControlsService.playingState$).subscribe(s => this.playingState = s);
        Utils.safeSubscribe(this, this.dvrControlsService.timestamp$).subscribe(s => this.timestamp = s);
    }

    async ngOnChanges(changes: SimpleChangesTyped<DvrControlsComponent>): Promise<void> {
        if (changes.range && changes.range.firstChange && changes.range.currentValue) {
            if (changes.range.currentValue.from) this.minDate = changes.range.currentValue.from;
            if (changes.range.currentValue.to) this.maxDate = changes.range.currentValue.to;
            this.hideLiveOption = this.maxDate.valueOf() < new Date().valueOf();
        }
    }

    ngOnDestroy() {
        this.destroyed = true;
        this.stopLiveTimeInterval();
        this.dvrControlsService.reset();
    }

    dateClassBasedOnProps = (date: Date): MatCalendarCellCssClasses => {
        if (this.disabledDatesUntil && moment(date).isSameOrBefore(moment(this.disabledDatesUntil))) return 'is-disabled';
        return '';
    };

    private handleRangeStateChange = async (state: { range: DateRange }) => {
        if (!state || !Object.keys(state)?.length) return;
        await this.setupRangeSliderRange(state.range, true);
    };

    private emitPlaybackStateUpdate(inPlaybackMode: boolean) {
        const state: DvrControlsPlaybackState = { timestamp: this.timestamp, inPlaybackMode };
        this.playbackStateUpdated.emit(state);
        this.dvrControlsService.playbackState$.next(state);
    }

    private emitControlsStateUpdate(toggleDvrState: boolean, firstLoad: boolean) {
        const range = { from: new Date(this.sliderRange.start), to: new Date(this.sliderRange.end) };
        const state: DvrControlsState = {
            togglePlaybackMode: toggleDvrState,
            range,
            firstLoad,
            timestamp: this.timestamp,
            inPlaybackMode: this.inPlaybackMode,
        };
        this.controlsStateUpdated.emit(state);
    }

    private emitPlaybackPlayingStateUpdate(isPlaying: boolean) {
        const event: DvrControlsPlayingState = { timestamp: this.timestamp, isPlaying };
        this.playingStateUpdated.emit(event);
        this.dvrControlsService.updatePlaybackPlayState(event);
    }

    private emitSliderMoving(moving: boolean) {
        if (this.dvrControlsService.sliderMoving !== moving) {
            this.dvrControlsService.sliderMoving$.next(moving);
            this.sliderMoving.emit(moving);
        }
    }

    private async setupControlsData() {
        this.isLoading = true;
        try {
            await this.getTimeFormatData();
            if (!this.initialTime) this.startLiveTimeInterval();
            if (this.range && !this.initialTime) {
                await this.setupRangeSliderRange(this.range, true);
            } else {
                await this.setupRangeSlider(this.initialTime ? moment(this.initialTime) : moment(new Date()), true);
            }

        } catch (e) {
            console.error(e);
            this.error = true;
        } finally {
            this.isLoading = false;
        }
    }

    private getTimeFormatData() {
        try {
            this.locale = this.translationService.getLocale();
            this.facilityTimezone = this.facilityService.getCurrentFacility()?.timezone || moment.tz.guess();
        } catch (e) {
            console.error(e);
        }
    }

    formatSliderLabel = (value: number | null) => {
        if (value && this.facilityTimezone) return DateTimeUtil.timeToLocaleString(value.valueOf(), this.locale, this.facilityTimezone, true);
    };

    private stopLiveTimeInterval() {
        if (this.liveInterval) {
            clearInterval(this.liveInterval);
            this.liveInterval = null;
        }
    }

    private startLiveTimeInterval(interval: number = 1000): void {
        if (this.destroyed) return;
        this.stopLiveTimeInterval();

        let endOfToday: number = moment().clone().endOf('day').valueOf();
        this.liveInterval = window.setInterval(async () => {
            this.timestamp = new Date().getTime();
            this.dvrControlsService.updateTimestamp(this.timestamp);
            if (this.timestamp > endOfToday) {
                endOfToday = moment().clone().endOf('day').valueOf();
                await this.changeDay(Direction.Next);
            }
        }, interval);
    }

    private async setupRangeSlider(day: moment.Moment, initialSetup: boolean = false) {
        const range = { from: day.toDate(), to: day.toDate() };
        await this.setupRangeSliderRange(range, initialSetup);
    }

    private async setupRangeSliderRange(range: DateRange, initialSetup: boolean = false) {
        this.isLoading = true;

        if (!range.to) range.to = moment(range.from).clone().tz(this.facilityTimezone).endOf('day').toDate();
        this.range = { ...range };

        const now: moment.Moment = moment().clone();
        const startTime = moment(range.from).clone().tz(this.facilityTimezone).startOf('day');
        const endTime = moment(range.to).clone().tz(this.facilityTimezone).endOf('day');
        const nowDate = now.clone().tz(this.facilityTimezone).toISOString(true).split('T')[0];
        const fromDate = startTime.toISOString(true).split('T')[0];
        const isToday = fromDate === nowDate;
        const toDate = moment(range.to).clone().tz(this.facilityTimezone).toISOString(true).split('T')[0];
        const isSameDay = fromDate === toDate;
        this.daysDiff = isToday || isSameDay ? 1 : moment(range.to).diff(range.from, 'days');

        if (this.type !== DvrType.Week) {
            this.type = isToday || isSameDay //  || day
                ? DvrType.Day
                : DvrType.Custom;
        }

        const hourInterval = this.daysDiff < 4 ? 6 : this.daysDiff < 8 ? 12 : 24;

        const getDvrPlaybackTimestamp = (start: moment.Moment, end: moment.Moment) => {
            return isToday
                ? Date.now()
                : end.isAfter(now) && this.type === DvrType.Day
                    ? start.valueOf()
                    : end.valueOf();
        };

        const getIntervalIcon = (time: Date): string => {
            const timeOfDay = moment(time).tz(this.facilityTimezone).format('HH:mm:ss');
            switch (true) {
                case moment(timeOfDay, 'HH:mm:ss').isBefore(moment('06:00:00', 'HH:mm:ss')):
                    return 'fas fa-moon';
                case moment(timeOfDay, 'HH:mm:ss').isBetween(moment('05:59:59', 'HH:mm:ss'), moment('12:00:00', 'HH:mm:ss')):
                    return 'fas fa-sunrise';
                case moment(timeOfDay, 'HH:mm:ss').isBetween(moment('11:59:59', 'HH:mm:ss'), moment('18:00:00', 'HH:mm:ss')):
                    return 'fas fa-sun';
                case moment(timeOfDay, 'HH:mm:ss').isBetween(moment('17:59:59', 'HH:mm:ss'), moment('20:00:00', 'HH:mm:ss')):
                    return 'fas fa-sunset';
                case moment(timeOfDay, 'HH:mm:ss').isAfter(moment('19:59:59', 'HH:mm:ss')):
                    return 'fas fa-moon';
            }
        };

        const getIntervalTicks = () => {
            const intervals: RangeValue[] = [];
            do {
                intervals.push({
                    date: startTime.toDate(),
                    label: moment(startTime).tz(this.facilityTimezone).format('h:mm A'),
                    icon: getIntervalIcon(startTime.toDate()),
                });
                startTime.add(hourInterval, 'hours');
            } while (
                startTime.isBefore(endTime.add(1, 'second'))
            );

            return intervals;
        };

        this.activeDay = { date: range.from, label: startTime.format('dddd MMM D, YYYY') };
        if (this.type !== DvrType.Custom) this.selectedDate = range.from;

        const weekStart = moment(range.from).clone().add(-6, 'day');
        this.activeWeek = { date: weekStart.toDate(), label: weekStart.format('dddd MMM D, YYYY') };

        this.timestamp = getDvrPlaybackTimestamp(startTime, endTime);
        this.sliderRange = { start: moment(startTime).clone().valueOf(), end: moment(endTime).clone().valueOf() };
        this.customRangeLabel = `${new Date(this.sliderRange.start).toDateString()} - ${new Date(this.sliderRange.end).toDateString()}`;
        this.rangeTicks = getIntervalTicks();

        await this.handleDvrTimeSelection(isToday ? null : this.timestamp, initialSetup);

        this.initialTime = null;
        this.isLoading = false;
    }

    private async changeDay(direction: Direction) {
        const newDay = direction === Direction.Next ? moment(this.activeDay.date).clone().add(1, 'day') : moment(this.activeDay.date).subtract(1, 'day');
        await this.setupRangeSlider(newDay);
    }

    async changeMinute(direction: Direction) {
        await this.changeTime(direction, 60000);
    }

    async changeSecond(direction: Direction) {
        await this.changeTime(direction, 1000);
    }

    private async changeTime(direction: Direction, timeMs: number) {
        this.stopLiveTimeInterval();
        this.emitSliderMoving(true);
        this.lastDirection = direction;
        const currentDay: moment.Moment = moment(this.timestamp).tz(this.facilityTimezone).clone();
        this.timestamp = direction === Direction.Next ? this.timestamp + timeMs : this.timestamp - timeMs;
        if (currentDay.startOf('day').valueOf() !== moment(this.timestamp).tz(this.facilityTimezone).startOf('day').valueOf()) this.changeDay(this.lastDirection);
        this.handleChangeTimeDebounce();
    }

    private lastDirection: Direction;
    private handleChangeTimeDebounce = debounce(async () => {
        await this.handleDvrTimeSelection(this.timestamp);
    }, 500, { leading: false, trailing: true });


    toggleDatepicker(): void {
        this.showCalendarPicker = !this.showCalendarPicker;
    }

    async handleDateSelection(event: string) {
        if (this.disabledDatesUntil && moment(event).isSameOrBefore(moment(this.disabledDatesUntil))) {
            if (this.disabledDatesClick) this.disabledDatesClick(moment(event));
            this.showCalendarPicker = false;
            return;
        }
        await this.setupRangeSlider(moment(event));
    }

    async jumpToNow() {
        await this.setupRangeSlider(moment(new Date()));
    }

    closeDvr(): void {
        this.hideDvr.emit();
    }

    handleSliderMoved() {
        this.stopLiveTimeInterval();
        this.emitSliderMoving(true);
    }

    async handleDvrTimeSelection(timestamp?: number, firstLoad: boolean = false, tearDown: boolean = false): Promise<void> {
        AnalyticsService.track(StObject.MapPlaybackTimeSelection, StAction.Set, this.constructor.name);
        let toggleDvrState: boolean;

        this.emitSliderMoving(false);

        const shouldBeLive = !timestamp || timestamp > new Date().getTime();
        if (shouldBeLive) {
            toggleDvrState = this.inPlaybackMode;
            this.emitPlaybackPlayingStateUpdate(false);
            if (toggleDvrState) {
                this.startLiveTimeInterval();
            }
            this.timestamp = new Date().getTime();
        } else {
            toggleDvrState = !this.inPlaybackMode;
            if (toggleDvrState) this.stopLiveTimeInterval();
            this.timestamp = timestamp;
        }
        this.dvrControlsService.updateTimestamp(this.timestamp);
        this.emitPlaybackStateUpdate(!shouldBeLive);

        // don't emit if called from ngOnDestroy
        if (!tearDown) {
            this.emitControlsStateUpdate(toggleDvrState, firstLoad);
        }
    }

    togglePlayback(): void {
        if (!this.inPlaybackMode) return;
        this.emitPlaybackPlayingStateUpdate(!this.isPlaying);
    }
}
