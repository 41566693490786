<div class="wrapper" (click)="click()" *ngIf="item">
    <div class="icon-display" *ngIf="item?.itemType?.icon?.value">
        <app-icon [fontSize]="'25px'" [faIcon]="item.itemType.icon.value"></app-icon>
    </div>

    <div class="content">
        <div class="content-row">
            <p class="name">{{item.name}}</p>
        </div>

        <div class="content-row">
            <p *ngIf="item?.owner?.name" class="company-name">{{item?.owner?.name}}</p>
            <div class="mLa company-logo" *ngIf="item?.owner?.logo">
                <img [src]="item?.owner?.logo">
            </div>
        </div>
    </div>
</div>