import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class ToolTipService {
    private isShowing$ = new BehaviorSubject<boolean>(false);

    get showing$(): Observable<boolean> {
        return this.isShowing$.asObservable();
    }

    setIsShowing(loading: boolean): void {
        this.isShowing$.next(loading);
    }
}
