import { idProp, model, Model, prop } from '@weavix/mobx';

@model('Craft')
export class Craft extends Model({
    id: idProp,
    accountId: prop<string>(),
    name: prop<string>(),
    code: prop<string>(),
    color: prop<string>(),
    tags: prop<string[]>(),
    disabled: prop<boolean>(),
}) {
}
