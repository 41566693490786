import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeService {

    static lightTheme: boolean;

    constructor() {
    }

    static setLightTheme(lightTheme: boolean) {
        this.lightTheme = lightTheme;
    }

    static getLightTheme(): boolean {
        return this.lightTheme;
    }
}
