import { ListButton } from 'components/button-list/button-list.component';
import { MapMode, MapTableMode } from 'weavix-shared/models/weavix-map.model';

export const MapModeButtons: {[key in MapMode]?: ListButton} = {
    [MapMode.Active]: {
        textKey: `shared.map.mode.${MapMode.Active}`,
        keyValue: MapMode.Active,
        selected: false,
    },
    [MapMode.Satellite]: {
        textKey: `shared.map.mode.${MapMode.Satellite}`,
        keyValue: MapMode.Satellite,
        selected: false,
    },
    [MapMode.NoSatellite]: {
        textKey: `shared.map.mode.${MapMode.NoSatellite}`,
        keyValue: MapMode.NoSatellite,
        selected: false,
    },
    [MapMode.All]: {
        textKey: `shared.map.mode.${MapMode.All}`,
        keyValue: MapMode.All,
        selected: false,
    },
};

export const MapTableModeButtons: {[key in MapTableMode]: ListButton} = {
    [MapTableMode.Map]: {
        textKey: `shared.map.display.${MapTableMode.Map}`,
        keyValue: MapTableMode.Map,
        selected: true,
        icon: 'fas fa-map',
    },
    [MapTableMode.List]: {
        textKey: `shared.map.display.${MapTableMode.List}`,
        keyValue: MapTableMode.List,
        selected: false,
        icon: 'fas fa-list',
    },
};
